
import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Footer from './components/frames/Footer';
import Main from './components/frames/Main';
import Contact from './screens/contact/Contact';
import Blog from './screens/blog/Blog';
import About from './screens/about/About';
import Signature from './screens/signature/Signature';
import Account from './screens/account/Account.js';
import { isMobile } from 'react-device-detect';
import FooterMobile from './components/frames/FooterMobile';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileState } from './redux/actions/screenActions';
import AlertContainer from './components/interfaces/alertContainer/AlertContainer';
import ToastContainer from './components/interfaces/toastContainer/ToastContainer';
import BETA from './screens/beta/BETA';
import TermsPage from './screens/terms/TermsPage.js';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return ((height / width) > 1)
}


export default function App() {
  const location = useLocation();
  const isAccountPath = location.pathname.toLowerCase() === "/account";
  const localMobile = useSelector((state) => state.isMobile)
  const dispatch = useDispatch()

  useEffect(() => {
    function handleResize() {
      const width = getWindowDimensions();
      if (width) {
        dispatch(isMobileState(true))
      } else {
        dispatch(isMobileState(false || isMobile))
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);

  })

  
  useEffect(() => {
    dispatch(isMobileState(isMobile))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='AppScreen'>
      <div className='AppBody'>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/account" element={<Account isMobile={localMobile} />} />
          <Route path='/blog' element={<Blog />} />
          {/*<Route path='/plans' element={<Signature />} />*/}
          <Route path='/plans' element={<BETA />} />
          <Route path='/about' element={<About />} />
          <Route path='/term-privacy-policy' element={<TermsPage />} />
        </Routes>
      </div>
      {(!isAccountPath) && (localMobile
        ? <FooterMobile />
        : <Footer />)}
      <AlertContainer />
      <ToastContainer />
    </div>
  )
}

