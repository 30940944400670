import React from 'react'
import './InputBox.css'
export default function InputBox(props) {
    const STYLE = {
        marginTop: `${props?.marginTop || 0}px`,
        marginLeft: `${props?.marginLeft || 0}px`,
        marginRight: `${props?.marginRight || 0}px`,
        marginBottom: `${props?.marginBottom || 0}px`,
        fontSize: `${props?.fontSize || 14}px`,
        fontWeight: `${props?.fontWeight || '400px'}`,
        alignSelf: `${props?.textAlign || 'center'}`,
        alignItems: `${props?.textItems || ''}`,
        color: `${props?.color || '#a8a8a8'}`,
        width: `${props.width || "244px"}`,
        maxHeight: `${props.height || "244px"}`,
    }

    const STYLEINPUT = {
        textAlign: `${props?.inputTextAlign || 'center'}`,
        fontSize: `${props?.inputFontSize || 14}px`,
        marginBottom: `${props?.marginBottomInput || 0}px`,
        marginLeft: `${props?.marginLeftInput || 0}px`,
        marginRight: `${props?.marginRightInput || 0}px`,
        width: `${props?.widthInput || '100%'}`,
        paddingLeft: `${props.paddingX || props.padding || "18"}px`,
        paddingRight: `${props.paddingX || props.padding || "18"}px`,
        paddingTop: `${props.paddingY || props.padding || "9"}px`,
        paddingBottom: `${props.paddingY || props.padding || "9"}px`,
    }

    const checkSpecialChar = (e) => {
        if (props?.noSpecialCharacter) {
            if (!/[0-9a-zA-Z]/gi.test(e.key)) {
                e.preventDefault();
            }
        }
    };
    return (
        <div className='InputBox' style={STYLE} title={props?.title || ''}>
            {
            //props?.label || ''
        }
            <input
                style={STYLEINPUT}
                className={props?.styleInput || 'InputLabel'}
                type={props?.type || ''}
                value={props?.value || ''}
                defaultValue={props?.defaultValue || ''}
                placeholder={props?.placeholder || `${props?.newItem || ''}`}
                onKeyDown={(e) => checkSpecialChar(e)}
                onChange={(e) => props?.setValue({ paramName: props?.paramName, value: e.target.value })}
            >
            </input>
            {<i>
            {props?.icon ? props?.icon : ''}
            </i>
        }
        </div>
    )
}
