import React, { useState, useEffect, useRef } from 'react';
import './TextAnime.css'

export default function TextAnime(props) {
    const [text, setText] = useState('');
    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showCursor, setShowCursor] = useState(false)
    const timeoutRef = useRef(null);
    const cursorIntervalRef = useRef(null);
    const [isActive, setIsActive] = useState(true)

    useEffect(() => {
        const urlNow = window.location.href;
        if (urlNow.includes('/account')) {
            setIsActive(false);
        } else {
            setIsActive(true)
        }
    }, [window.location.href]);


    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            if (isDeleting) {
                if (text.length > 0) {
                    setText((prevText) => prevText.slice(0, -1));
                } else {
                    setIsDeleting(false);
                    setCurrentIndex((prevIndex) => prevIndex + 1);
                }
            } else {
                if (currentIndex < props?.dataTextAnime[currentPhraseIndex].length) {
                    setText((prevText) => prevText + props?.dataTextAnime[currentPhraseIndex][currentIndex]);
                    setCurrentIndex((prevIndex) => prevIndex + 1);
                } else {
                    setIsDeleting(true);
                }
            }
        }, 200);
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [currentIndex, currentPhraseIndex, isDeleting, props?.dataTextAnime, text]);

    useEffect(() => {
        if (currentIndex === props?.dataTextAnime[currentPhraseIndex].length && isDeleting === false) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                setIsDeleting(true);
            }, 1000);
        } else if (
            currentIndex === props?.dataTextAnime[currentPhraseIndex].length &&
            isDeleting === true &&
            text === ''
        ) {
            clearTimeout(timeoutRef.current);
            const nextPhraseIndex = (currentPhraseIndex + 1) % props?.dataTextAnime.length;
            setCurrentPhraseIndex(nextPhraseIndex);
            setCurrentIndex(0);
            setIsDeleting(false);
        }
    }, [currentIndex, currentPhraseIndex, isDeleting, props?.dataTextAnime, text]);

    useEffect(() => {
        cursorIntervalRef.current = setInterval(() => {
            setShowCursor((prevShowCursor) => !prevShowCursor);
        }, 500);

        return () => {
            clearInterval(cursorIntervalRef.current);
        };
    }, []);

    return (

        <div className='TextAnimeBody'>
            {isActive &&
                <>
                    <div className='TitleAnime'>
                        {text}{showCursor && '|'}
                    </div>
                    <div className='TextStatic'>
                        {props?.Text}
                    </div>
                </>
            }
        </div>
    );
};

