import React, { useState } from 'react'
import './FooterMobile.css'
import { useNavigate } from 'react-router-dom';
import MenuSlideUp from '../menus/menuSlideup/MenuSlideUp';
import { ReactComponent as Logo } from '../../images/LogotipoBeta.svg';
import NiIcon from '../niIcons/NiIcons';
import Title from '../title/Title';

export default function FooterMobile() {
  const [isOpen, setIsOpen] = useState(false)

  const navigate = useNavigate();
  
  return (
    <div className={`Footer ${isOpen && "Opened"}`}>
      <div className='MenuButton' onClick={()=>setIsOpen(!isOpen)}>
        <Title color="" fontSize={24}>Menu</Title>
       <NiIcon
          style={{ width: '28px', height: '46px', fill: '#39496D' }}
          styleIcon={{ minX: "0", minY: "0", width: "28", height: "8" }} name={isOpen ? "arrowdown" : "arrowup"} /> 
      </div>
      {isOpen && 
        <MenuSlideUp />
      }
       <div className='LogoFooter'>
            <Logo style={{ cursor: 'pointer' }} width={'146.18px'} heigth={'35px'} onClick={() => navigate('/')} />
      </div>
    </div>
  )
}
