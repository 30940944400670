import React from 'react';
import { Icons } from './IconsLibrary';


export default function NiIcon({name, style, styleIcon}) {
  return (
    <svg  style={{...style}}  viewBox={`${styleIcon?.minX || "0"} ${styleIcon?.minY || "0"} ${styleIcon?.width || "25"} ${styleIcon?.height || "25"}`}>
      {Icons?.[name]?.map((item)=> (item) )}
    </svg> 
    
  )
}