
import React, { useState } from 'react';
import './SendEmail.css';
import Title from '../../components/title/Title.js';
import Input from '../../components/input/Input.js';
import Button from '../../components/buttonNav/Buttons';

export default function SendEmailEvents() {
    const [message, setMessage] = useState({});
    const [contact, setContact] = useState({});
    const [email, setEmail] = useState({});


    const handleChange = () => {
       // console.log(message.value, contact.value, email.value)
    }

    return (
        <div className='SendEmailEventsBody'>
            <Title
                marginTop={52}
                marginBottom={45}
                marginLeft={15}
                fontWeight={900}
                textAlign={'left'}
                fontSize={20}
                title={<div style={{ width: '81%' }}><span>Estamos aqui para auxiliar você!</span><br />
                    <span style={{ color: 'black' }}> Entre em contato conosco e teremos prazer em ajudar.</span>
                </div>}
            />
            <div className='TitleSendEmail'>
                <Input
                    type={'email'}
                    fontWeight={'800px'}
                    color={'#353535'}
                    fontSize={18}
                    inputTextAlign={'left'}
                    backgroundColor={'#FFFFFF'}
                    marginLeftInput={15}
                    padding={10}
                    marginBottom={15}
                    widthInput={'580px'}
                    label={'E-mail:'}
                    values={email.value}
                    paramName={'email'}
                    placeholder={'Inserir seu e-mail para contato'}
                    setValue={setEmail}
                />
            </div> 
            <div className='TitleSendEmail'>
                <Input
                    type={'text'}
                    fontWeight={'800px'}
                    color={'#353535'}
                    fontSize={18}
                    inputTextAlign={'left'}
                    backgroundColor={'#f4f4f4'}
                    marginLeftInput={15}
                    padding={10}
                    marginBottom={15}
                    widthInput={'580px'}
                    label={'Para:'}
                    values={"contato@nodeiot.com.br"}
                    setValue={()=>{}}
                />
            </div>
            <div className='SubjectSendEmail'>
                <Input
                    fontWeight={'800px'}
                    color={'#353535'}
                    fontSize={18}
                    inputTextAlign={'left'}
                    backgroundColor={'#FFFFFF'}
                    marginLeftInput={15}
                    values={contact.value}
                    label={'Assunto:'}
                    placeholder={'Inserir assunto'}
                    paramName={'subject'}
                    setValue={setContact}
                />
            </div>

            <textarea
                value={message.value}
                className='TextAreaForm'
                fontFamily='Inter'
                fontSize={'14px'}
                placeholder='Digite o texto para mensagem'
                onChange={(text) => setMessage(text.target.value)}>
            </textarea>
            <Button
                marginBottom={25}
                Type={'Back'}
                Size={'Small'}
                text={'Enviar'}
                onClicks={handleChange}
            />

        </div>
    )
}
