import React from 'react'
import './MenuSlideUp.css'
import SocialIcons from '../../socialIcons/SocialIcons';
import { useNavigate } from 'react-router-dom';
import ListLink from '../../listLinks/ListLink';

export default function MenuSlideUp() {
  const navigate = useNavigate();
    const dataSocialIcons = [
        {
            Name: 'instagram',
            Title: '@nodeiotbr',
            Link: () => window.open("https://www.instagram.com/nodeiotbr/", "_blank")
        }, {
            Name: 'linkedin',
            Title: '@NodeIOT',
            Link: () => window.open("https://www.linkedin.com/company/nodeiot/about/", "_blank")
        }, {
            Name: 'email',
            Title: 'contato@nodeiot.com.br',
            Link: () => navigate('/contact')
        }
    ]

    const dataLinkNav = [
        {
            Label: 'Início',
            Link: () => navigate('/'),
            Style: 'mobileItemLink'
        }, {
            Label: 'A Empresa',
            Link: () => navigate('/about'),
            Style: 'mobileItemLink'
        }, {
            Label: 'Notícias',
            Link: () => navigate('/blog'),
            Style: 'mobileItemLink'
        },
        {
            Label: 'Seja um BETA',
            Link: () => navigate('/account'),
            Style: 'mobileBetaLink'
        },

    ]
    return (
        <div className="MenuSlideUp">
            <ListLink
                isMobile
                dataListLinks={dataLinkNav}
            />
            <SocialIcons
                Size='Ico'
                dataSocialIcons={dataSocialIcons}
            />

        </div>
    )
}
