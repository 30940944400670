
import {
    ADD_ALERT_FAIL,
    ADD_ALERT_NOTIFICATION,

} from "../constants/alertConstants";


export const addAlertNotification = ({ status, icon, message, actionConfirm, hiddenCancel, cancelText, confirmText }) => (dispatch) => {
    try {
        dispatch({
            type: ADD_ALERT_NOTIFICATION,
            payload: {
                status,
                info:
                {
                    icon,
                    message,
                    actionConfirm,
                    hiddenCancel,
                    cancelText,
                    confirmText
                }
            }
        })
        
    } catch (error) {
        dispatch({
            type: ADD_ALERT_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

