import React from 'react'
import './About.css';
import Dashboards from '../../images/Dashboards_e_HeatMaps.png'
import Header from '../../components/frames/Header';
import BoxMain from '../../components/frames/BoxMain';
import { useTranslation } from "react-i18next";


export default function About() {
    const { t } = useTranslation('common');
    return (
        <div className='About'>
            <Header
                Title={'Sobre Nós'}
            />
                <BoxMain
                    h1={t('About.Step1.H1')}
                    data = {[
                        {
                            h2:t('About.Step1.H2'),
                            h3:t('About.Step1.H3')
                        },{
                            h2:t('About.Step2.H2'),
                            h3:t('About.Step2.H3')
                        },{
                            h2:t('About.Step3.H2'),
                            h3:t('About.Step3.H3')
                        },{
                            h2:t('About.Step4.H2'),
                            h3:t('About.Step4.H3')
                        }
                    ]}
                    Image={<img src={Dashboards} alt='Dashboards' />}
                    FlexDirection={'row-reverse'}
                />
        </div>
    )
}
