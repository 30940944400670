import React from 'react';
import './ListLink.css';

export default function ListLink(props) {
    return (
        <div className={`ListLinksBody ${props.isMobile && "mobileListLink"}`}>
            {props?.Title && <div className='TitleLinks'>{props?.Title}</div>}
            {props?.dataListLinks.map((item, key) => (
                <div className={`LinksItems ${item.Style}`} key={'listLink-'+key} onClick={item.Link}>{item.Label}</div>
            ))
            }
        </div>
    )
}
