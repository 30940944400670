import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './FormOptions.css';
import Title from '../../title/Title'
import Button from '../../buttonNav/Buttons'
import { useTranslation } from "react-i18next";
import { flowForm } from '../../../redux/actions/infoActions';


export default function FormOptions(props) {
  const info = useSelector((state) => state.infoLoading)
  const step = info.step
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const STYLE = {
    height: props?.height || '45px',
    textAlign: props?.textAlign || 'left',
    justifyContent: props?.justifyContent || 'start',
  }

  const handleChange = () => {
  }
  return (
    <div className='FormOptionsBody'>
      <Title
        title={props?.title}
        marginTop={25}
        marginBottom={10}
        fontWeight={700}
        fontSize={28}
        color={'black'}
      />
      <div className='FormItemsFlow'>
        {props?.dataOptions && props?.dataOptions.map((item, key) => (
          <div className='ItemSelectFlow'
            style={STYLE}
            key={key}
            onClick={() => dispatch(flowForm({ type: 'INFO_FLOWFORM_UPDATE', status: 'ENABLE', value: item.label, paramName: props.paramName, step: step + 1 }))}
          >
            {item?.icon && <div className='IconSelectFlow'> {item?.icon} </div>}
            {item.label}
          </div>
        ))}
        
      </div>
        <Button
          Type={step === 4 ? 'DefaultColor' : 'Navigation'}
          text={step === 4 ? t('components.buttons.register') : t('components.buttons.back')}
          marginTop={15}
          marginBottom={16}
          onClicks={step === 4 ? handleChange : () => dispatch(flowForm({ type: 'INFO_FLOWFORM_UPDATE', status: 'ENABLE', step: step - 1 }))}
        />
    </div>
  )
}
