import React from 'react';
import './Account.css'
import { useSelector} from 'react-redux';
import AccountFlow from './formFLow/AccountFlow'
import AccountPreview from './formFLow/AccountPreview';

export default function Account(props) {
  const info = useSelector((state)=>state.infoLoading)
  const step = info.step
  return (
    <div className='AccountBody'>
      {step ? <AccountFlow isMobile={props.isMobile} /> : <AccountPreview isMobile={props.isMobile} />}
    </div>
  )
}