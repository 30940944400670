import React from 'react';
import Button from '../../components/buttonNav/Buttons';
import './Steps.css';
import sideImage from '../../images/School.gif';
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';



export default function IotRevolution() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const isMobile = useSelector((state) => state.isMobile)
  return (
    <div className={`Steps ${!isMobile && "desktop"}`}> 
    {!isMobile &&
      <div className='ImagesStep2'>
        <img src={sideImage} style={{ height: '73vh', transformOrigin: 'left center', margin: '5px' }} />
      </div>}
      <div className={`${isMobile ? "MobileSteps" : "leftBox"}`}>
        <h1 style={{paddingTop: "45px"}}>
          <Trans i18nKey={t('Screen.Home.Step3.H1')}
            components={{
              highLight: <span style={{ color: '#265FFF' }} />
            }} />
        </h1>
        <h2>
          {t('Screen.Home.Step3.H2')}
        </h2>
        <div>
          <h3>
            {t('Screen.Home.Step3.H3')}
          </h3>
          <Button
            marginTop={26}
            Size={'Large'}
            Type={'DefaultColor'}
            text={`${t('Screen.Home.Step3.TextButton')}`}
            onClicks={() => navigate('/account')}
            justifyContent={isMobile ? "center" : "start"}
          />
        </div>
      </div>
    </div>
  )
}
