import React from 'react';
import './ToastContainer.css';
import Toast from '../../notifications/toast/Toast';
import { useSelector, useDispatch } from 'react-redux';
import { removeToastAction } from '../../../redux/actions/toastAction';

export default function ToastContainer() {
    const dispatch = useDispatch();
    const dataOptions = useSelector((state) => state.toastNotification);
    const isMobile = useSelector((state) => state.isMobile)

    return (
        dataOptions?.alerts?.length > 0 &&
        <div className='ToastAlertContainer'>
            {dataOptions?.alerts?.map((item, index) => (
                    <div className={`ToastAnime ${isMobile && "ToastMobile"}`} key={index} >
                        <Toast
                            close={(e) => dispatch(removeToastAction(e))}
                            icon={item.icon}
                            message={item.message}
                            status={item.status}
                        />
                    </div>
                ))}
        </div>
    );
}
