import React from 'react';
import './Buttons.css'


export default function Button(props) {
    const STYLE = {
        display:'flex',
        marginTop: `${props?.marginTop || 0}px`,
        marginBottom: `${props?.marginBottom || 0}px`,
        marginLeft: `${props?.marginLeft || 0}px`,
        marginRight: `${props?.marginRight || 0}px`,
        padding: 0,
        justifyContent: `${props?.justifyContent || 'center'}`,
        alignItems: `${props?.alignItems || 'center'}`,
        zIndex: props?.zIndex
    }

    return (
        <div style={STYLE} title={props?.title || ''}>
            <button 
                className={`${props?.Size || 'DefaultButton'} ${props?.Type|| 'DefaultColor'}`}
                onClick={props?.onClicks}
                disabled={props?.isDisabled || ''}
                hidden={props?.isHidden}>
                {props?.text || props?.children}
            </button>
        </div>
    )
}
