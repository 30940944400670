import {
    TOOLS_TOGGLESTATE_DISABLE, 
    TOOLS_TOGGLESTATE_ENABLE, 
    TOOLS_TOGGLESTATE_ERROR } from "../constants/toolsConstants.js";

export const toolsToggleStateReducer = (state = {status: false, info:{step: 1}}, action) => {
    switch (action.type) {  
        case TOOLS_TOGGLESTATE_ENABLE: 
            return {status: true, info: action.payload};
        case TOOLS_TOGGLESTATE_DISABLE: 
            return {status: false, info: action.payload};
        case TOOLS_TOGGLESTATE_ERROR:
            return {status: false, Error: action.payload};
        default: 
            return state;
    }
}