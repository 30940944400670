import React from 'react';
import './Terms.css'
import { useSelector } from 'react-redux';
import Title from '../../components/title/Title';
import { Trans, useTranslation } from "react-i18next";

export default function TermsPage(props) {
    const isMobile = useSelector((state) => state.isMobile)

    const { t } = useTranslation('common');
    return (
        <div className={isMobile ? 'TermsPage mobile' : 'TermsPage'}>
            <div className={'TermPage-contents'}>
                <Title textAlign="left" fontSize={32} marginBottom={20}>{t("terms.Title")}</Title>
                <div className='content'>
                    <Title textAlign="left" fontSize={24} color="#39496D">{t("terms.SubTitle")}</Title>
                    <Title textAlign="left" fontSize={18} color="#39496D" fontWeight={400}>{t("terms.content")}</Title>
                    {['1', '2', '3', '4'].map((nItens, key) => (
                        <div className='content-itens'>
                            <Title textAlign="left"  fontSize={24}  color="#39496D" marginBottom={10}>{t(`terms.Item${1}-${key + 1}.Title`)}</Title>
                            {t(`terms.Item${1}-${key + 1}.content`, { returnObjects: true }).map((text) =>
                                <span>{text}</span>
                            )}
                        </div>
                    ))}
                </div>
                <div className='content'>
                    <Title textAlign="left" fontSize={24} color="#39496D">{t("terms.SubTitle2")}</Title>
                    <Title textAlign="left" fontSize={18} fontWeight={400} color="#39496D">{t("terms.content2")}</Title>
                    {['1', '2', '3', '4', '5'].map((nItens, key) => (
                        <div className='content-itens'>
                            <Title textAlign="left" fontSize={18} color="#39496D" marginBottom={10}>{t(`terms.Item${2}-${key + 1}.Title`)}</Title>
                            {t(`terms.Item${2}-${key + 1}.content`, { returnObjects: true }).map((text) =>
                                <span>{text}</span>
                            )}
                        </div>
                    ))}
                </div>
                <Title
                    textAlign="left"
                    color="#39496D"
                    fontSize={18} 
                    fontWeight={400}
                    marginBottom={10}>
                    {t(`terms.footer`)}
                </Title>

            </div>
        </div>
    )
}