import { TOOLS_TOGGLESTATE_ENABLE } from "../constants/toolsConstants";

export const toggleState = ({ info }) => (dispatch) => {
    try {
        dispatch({
            type: TOOLS_TOGGLESTATE_ENABLE,
            payload: info
        })
        
    } catch (error) {
        dispatch({
            type: "TOOLS_TOGGLESTATE_ERROR",
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}