import React from 'react'
import './SocialIcons.css'
import NiIcon from '../niIcons/NiIcons'
import { useSelector } from 'react-redux'

export default function SocialIcons(props) {
  const isMobile = useSelector((state) => state.isMobile)
    const STYLE = {
        flexDirection: `${props?.flexDirection || 'column'}`
    }
    return (
        <div className={`SocialIconsBody ${isMobile && "isMobile"}`} style={STYLE}>
            {props.dataSocialIcons.map((item, key) => (
                <div className={`SocialStyle`} key={key} title={item.Title} onClick={item.Link}>
                        <NiIcon
                            name={item.Name}
                            style={{ width: '25px', height: '25px', fill: "#FFFFFF", transform:`scale(${1})`}}
                            styleIcon={{ minX: "0", minY: "0", width: "25", height: "25"}}
                        />
                </div>
            ))}
        </div>
    )
}
