import React from 'react';
import './FormComponent.css'


export default function FormComponent(props) {
  return (
    
    <div className={`${props.isMobile ? "Mobile" : "FormComponentBody"}`}> 
      {props.children}
    </div>
  )
}
