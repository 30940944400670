import React from 'react';
import { useSelector } from 'react-redux';
import Alert from '../../notifications/alert/Alert'

export default function AlertContainer() {
  const alertNotification = useSelector((state)=>state.alertNotification)
  const status = alertNotification.status
  
  return (
    status && <Alert actionConfirm={alertNotification?.info?.actionConfirm} /> 
  )
}
