import {
    ADD_TOAST_FAIL,
    ADD_TOAST_NOTIFICATION,
    REMOVE_TOAST_NOTIFICATION
} from "../constants/toastConstants"

export const toastNotificationReducer = (state = { alerts: [] }, action) => {
    switch (action.type) {
        case REMOVE_TOAST_NOTIFICATION:
            state.alerts.splice(action.payload.index,1);
            return { alerts: state.alerts };
        case ADD_TOAST_NOTIFICATION:
            return { alerts: [...state.alerts, action.payload] };
        case ADD_TOAST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}
