import React, { useEffect, useState } from 'react';
import './BETA.css';
import ImageBETA from '../../images/BETA.png'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { SpeechBallon } from '../../images/Icons/SpeechBallon';


export default function BETA() {
    const { t } = useTranslation('common');
    const [showSpeech, setShowSpeech] = useState(false)
    const [onHover, setOnHover] = useState(false)
    const navigate = useNavigate();
    const isMobile = useSelector((state) => state.isMobile)
    const Speech = <SpeechBallon
    style={{ width: "100%" }}
    fontSize={19}
    data={
        [{ text: "Ei, psiu!", space: 5 }, 
        { text: "Venha acessar a versão",  space: 5},
        {text: " BETA da"},
        {text: "NodeIoT", sameLine: true, className: "BlueText",margin: 80},
        { text: "gratuitamente! ",}, 
        { text: "Clique aqui", className: "C2ABallon", space: 10, margin: 50, fontSize: 26 }]}>
</SpeechBallon>

    useEffect(() => {
        if (showSpeech && !onHover) {
            setTimeout(() => {
                setShowSpeech(false);
            }, (5000));
        }  else {
            setTimeout(() => {
                setShowSpeech(true);
            }, (1000));
        }
        
        
    }, [showSpeech]);

    return (
        <div className={isMobile ? 'MobileBeta' : 'Beta'}>
            <div className='ButtonBeta' onClick={()=>navigate('/account')} onMouseEnter={()=>{setOnHover(true); setShowSpeech(true)}}  onMouseLeave={()=>setOnHover(false)}>
                {(showSpeech || onHover) &&
                    Speech
                }
            </div>
        </div>
    )
}
