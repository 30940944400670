import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import common_pt from "./translations/pt/common.json";
import common_en from "./translations/en/common.json";
import i18next from "i18next";
import { BrowserRouter } from 'react-router-dom';


i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'pt',                              // language to use
  resources: {
    pt: {
      common: common_pt               // 'common' is our custom namespace
    },
    en: {
      common: common_en
    }
  },
})



ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
