import React from 'react'
import './ProgressBar.css'
import NiIcon from '../niIcons/NiIcons'

export default function ProgressBar(props) {
  const {step, info} = props?.formInfo

  return (
    <div className="ProgressBorder">
      {props?.dataSteps.map((item, key) => {
        const current = step - (key + 1);
        return(
        <div key={`progressBar-${key}`} className="Progress">
          <div className="Progress-Icon">
          <img src={item.icon} />
          </div>
          <div className="Progress-Text">{info[item.paramName]}</div>
          <div className="Progress-Bar">
            <div className={`Progress-Line ${(key <= 0) ? "" : current >= 0 ? "LineChecked" :"LineUnchecked"}`} />
            <div className={`Progress-Point ${current == 0 && "LineChecking"} ${current > 0 && "LineChecked"}`}>
              {current > 0 ? <NiIcon name="success"
                style={{ width: '25px', height: '25px', scale: '1.3', fill:"#FFFFFF"}}
                styleIcon={{ minX: "0", minY: "0", width: "25", height: "25" }} />:(
                  current == 0 && <div className="Square" />
                )
              }
            </div>
            <div className={`Progress-Line ${!(props?.dataSteps.length - 1 > Number(key)) ?" " : current > 0 ? "LineChecked" : "LineUnchecked"}`} />
          </div>
        </div>
        )
      })}
    </div>
  )
}
