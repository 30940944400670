import  { 
    applyMiddleware, 
    compose, 
    combineReducers} from 'redux';
import {
    legacy_createStore as createStore} from 'redux';
import thunk from 'redux-thunk';
import { 
    toolsToggleStateReducer } from './redux/reducers/toolsReducers';
import { 
    infoLoadingReducer, 
    saveFormFlowReducer } from './redux/reducers/infoReducers';
import { isMobileReducer } from './redux/reducers/screenReducers';
import { toastNotificationReducer } from './redux/reducers/toastReducer';
import { alertNotificationReducer } from './redux/reducers/alertReducer';

const initialState = {
    toolsToggleState : { info: {step: 1}}
};

const reducer = combineReducers({
    toolsToggleState:toolsToggleStateReducer,
    infoLoading:infoLoadingReducer,
    saveFormFlow:saveFormFlowReducer,
    isMobile:isMobileReducer,
    toastNotification:toastNotificationReducer,
    alertNotification:alertNotificationReducer
})

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducer, 
    initialState,
    composeEnhancer(applyMiddleware(thunk))
    );

export default store;