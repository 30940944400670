import React, { useEffect } from 'react';
import './Toast.css';
import NiIcon from '../../niIcons/NiIcons';
import { useTranslation } from "react-i18next";

export default function Toast(props) {
const { t } = useTranslation('common');
    useEffect(() => {
        setTimeout(() => {
            props.close(props.index);
       }, 5000);
     },[]);

    return (
        <div className='ToastBody' onClick={() =>props.close(props.index)}>
            <div className={`iconToast ${props.status+'Toast'}`}>
                <NiIcon
                    name={props?.icon}
                    style={{ width: '30px', height: '30px' }}
                    styleIcon={{ minX: "0", minY: "0", width: "25", height: "25" }}
                />
            </div>
            <div className='messageToast'>
                {t(`${props?.message}`)}
            </div>
        </div>
    )
}


