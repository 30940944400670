import {
    ISMOBILE_UPDATE, } from "../constants/screenConstants.js";

export const isMobileReducer = (state = false, action) => {
    switch (action.type) {  
        case ISMOBILE_UPDATE: 
            return action.payload;
        default: 
            return state;
    }
}