import React from 'react'
import './Blog.css';
import Header from '../../components/frames/Header';
import BoxMain from '../../components/frames/BoxMain';
import ManNews from '../../images/ManNews.png'



export default function Blog() {

    return (
        <div className='BlogBody'>
            <Header
                Title={'Notícias'}
            />
            <BoxMain
                h1={'Assunto IoT'}
                h2={'Fique por dentro de assuntos no mundo da tecnologia'}
                data={[
                    {
                        h2: "Edge Computing:",
                        h3: "O processamento de dados é feito mais próximo dos dispositivos de IoT, no limite da rede, em vez de depender exclusivamente de recursos na nuvem. Isso ajuda a reduzir a latência e melhorar a eficiência da rede."
                    },
                    {
                        h2: "Integração de Inteligência Artificial:",
                        h3: "A IA desempenha um papel fundamental na IoT, permitindo o processamento inteligente dos dados coletados por dispositivos e sensores. Algoritmos de aprendizado de máquina e técnicas de análise de dados são aplicados para obter insights valiosos e tomar decisões autônomas."
                    }, {
                        h2: "Aplicações Industriais (IIoT):",
                        h3: "A IoT tem um grande impacto na indústria, possibilitando a criação de fábricas inteligentes, monitoramento de ativos, manutenção preditiva e otimização de processos. A IIoT permite a coleta de dados em tempo real para melhorar a eficiência e a tomada de decisões nas operações industriais."
                    }, {
                        h2: "Integração com Setores Específicos:",
                        h3: " A IoT está sendo aplicada em diversos setores, como saúde (IoMT - Internet of Medical Things), agricultura (IoT Agrícola), cidades inteligentes, transporte conectado e residências inteligentes (domótica), entre outros. Cada setor tem suas próprias necessidades e desafios, impulsionando inovações específicas."
                    }
                ]}
                Image={
                    <img src={ManNews} height={'80%'} />
                }
            />
        </div>
    )
}
