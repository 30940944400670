import {
    INFO_FLOWFORM_DISABLE,
    INFO_FLOWFORM_UPDATE,
    INFO_FLOWFORM_ENABLE, 
    INFO_FLOWFORM_ERROR,
    INFO_SENDFORM_REQUEST,
    INFO_SENDFORM_SUCCESS,
    INFO_SENDFORM_FAIL,
    INFO_SENDFORM_RESET } from "../constants/infoConstants.js";

export const infoLoadingReducer = (state = {step: 0, status: false, info:{}}, action) => {
   
    switch (action.type) {  
        case INFO_FLOWFORM_ENABLE: 
            return {step: 1, status: true, info: {}};
        case INFO_FLOWFORM_UPDATE: 
            return {step: action.payload.step , status: true, info: {...state.info, ...action.payload.info} };
        case INFO_FLOWFORM_ERROR:
            return {status: false, Error: action.payload};
        case INFO_FLOWFORM_DISABLE:
            return {status: false, Error: action.payload};
        default: 
            return state;
    }
}

export const saveFormFlowReducer = (state = {loading: false, data: {}}, action) => {
   
    switch (action.type) {  
        case INFO_SENDFORM_REQUEST: 
            return {loading: true, data: {}, completed: false};
        case INFO_SENDFORM_SUCCESS: 
            return {loading: false, data: action.payload, completed: true };
        case INFO_SENDFORM_FAIL:
            return {loading: false, Error: action.payload,  completed: false};
        case INFO_SENDFORM_RESET: 
            return {loading: false,  data: {}, completed: false }
        default: 
            return state;
    }
}

