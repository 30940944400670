import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleState } from '../../redux/actions/toolsActions';
import './Main.css';
import Demonstration from '../../screens/home/Demonstration.js';
import Digitalization from '../../screens/home/Digitalization.js';
import IotRevolution from '../../screens/home/IotRevolution.js';
import Button from '../buttonNav/Buttons';
import NiIcon from '../niIcons/NiIcons';


export default function Main() {
  const isMobile = useSelector((state) => state.isMobile)
  const { info } = useSelector((state) => state.toolsToggleState)
  const { step } = info;
  const dispatch = useDispatch()


  const changeStep = (e) => {
    if (step + e < 1 ) {
      dispatch(toggleState({ info: { step: 3 } }))
    } else if (step + e > 3) {
      dispatch(toggleState({ info: { step: 1 } }))
    } else {
      dispatch(toggleState({ info: { step: step + e } }))
    }
  }

  useEffect(()=> {
    if (isMobile){
      dispatch(toggleState({ info: { step: 1 } }))
    }
  },[isMobile])
  
  return (
    <div className={`MainBody ${isMobile && 'MainMobile'}`} onWheelCapture={(e) => e.deltaY > 0 ? changeStep(+1) : changeStep(-1)}>
      {<div className={`ButtonNavigationLeft ${isMobile && 'MobileNavegation'}`} >
        <Button
          Size={isMobile ? 'mobileNavigation' : 'SiteNavigation'}
          Type={'Navigation'}
          text={
            <NiIcon
              name={'arrowleft'}
              style={{ width: '25px', height: '25px', fill: '#070A12' }}
              styleIcon={{ minX: "0", minY: "0", width: "25", height: "25" }}
            />}

          onClicks={(e) => changeStep(-1)}
        />
      </div>}
      {step === 1 && <Demonstration />}
      {step === 2 && <Digitalization />}
      {step === 3 && <IotRevolution />}
      {
        <div className={`ButtonNavigationRight ${isMobile && 'MobileNavegation'}`}>
          <Button
            Size={isMobile ? 'mobileNavigation' : 'SiteNavigation'}
            Type={'Navigation'}
            text={
              <NiIcon
                name={'arrowright'}
                style={{ width: '25', height: '25', fill: '#070A12' }}
                styleIcon={{ minX: "0", minY: "0", width: "25", height: "25" }}
              />
            }
            onClicks={(e) => changeStep(+1)}
          />
        </div>
      }
    </div>
  )
}


