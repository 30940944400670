import axios from "axios";
import {
    INFO_FLOWFORM_ENABLE,
    INFO_FLOWFORM_UPDATE,
    INFO_FLOWFORM_ERROR,
    INFO_SENDFORM_REQUEST,
    INFO_SENDFORM_SUCCESS,
    INFO_SENDFORM_FAIL,
    INFO_SENDFORM_RESET
}
    from "../constants/infoConstants.js";

export const flowForm = ({ step, paramName, value }) => (dispatch) => {
    try {
        dispatch({
            type: INFO_FLOWFORM_UPDATE,
            payload: { info: { [paramName]: value }, step: step }
        })

    } catch (error) {
        dispatch({
            type: INFO_FLOWFORM_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const startFlowForm = () => (dispatch) => {
    try {
        dispatch({
            type: INFO_FLOWFORM_ENABLE,
            payload: {}
        })
        dispatch({
            type: INFO_SENDFORM_RESET,
            payload: {}
        })

        

    } catch (error) {
        dispatch({
            type: INFO_FLOWFORM_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const sendForm = ({ fullName, companyName, email, whatsAppContact, age, category, area }) => async (dispatch) => {
    dispatch({
        type: INFO_SENDFORM_REQUEST,
        payload: {}
    })
    try {
        const { data } = await axios.post('/api/site/register', {
            fullName: fullName,
            email: email,
            age: age,
            objective: category,
            area: area,
            whatsapp: whatsAppContact
        });
        dispatch({
            type: INFO_SENDFORM_SUCCESS,
            payload: { data }
        })
    } catch (error) {
        dispatch({
            type: INFO_SENDFORM_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}
