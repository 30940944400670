import {
    ADD_ALERT_FAIL,
    ADD_ALERT_NOTIFICATION,
} from "../constants/alertConstants"

export const alertNotificationReducer = (state = { status: false, info: {} }, action) => {
    switch (action.type) {
        case ADD_ALERT_NOTIFICATION:
            return {status: action.payload.status, info: action.payload.info };
        case ADD_ALERT_FAIL:
            return {status: false, error: action.payload };
        default:
            return state;
    }
}
