import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import { ReactComponent as Logo } from '../../images/LogotipoBeta.svg';
import Button from '../../components/buttonNav/Buttons';

export default function Header(props) {
  const navigate = useNavigate();
  return (
    <div className='HeaderBody'>
      <Logo style={{cursor:'pointer'}} width={'146.18px'} heigth={'35px'} onClick={() => navigate('/')} />
      <div className='TextHeader'>
        <Button
          Size={'Small'}
          text={'Começar'}
          onClicks={() => navigate('/account')}
        />
      </div>
    </div>
  )
}
