import React from "react";
import './Title.css'

export default function Title(props) {
    const STYLE = {
        fontStretch: `${props?.fontStretch || 'normal'}`,
        textAlign: `${props?.textAlign || 'center'}`,
        fontStyle: `${props?.fontStyle || 'normal'}`,
        fontSize: `${props?.fontSize || 28}px`,
        fontWeight: `${props?.fontWeight || 'bold'}`,
        color: `${props?.color || ' #265FFF'}`,
        opacity: `${props?.opacity || ''}`,
        marginTop: `${props?.marginTop || 0}px`,
        marginRight: `${props?.marginRight || 0}px`,
        marginBottom: `${props?.marginBottom || 0}px`,
        marginLeft: `${props?.marginLeft || 0}px`,
    }

    return (
        <div className='TitleComponent' style={STYLE}>
            {props?.title || props?.children}
        </div>
    )

}