import { ISMOBILE_UPDATE} from "../constants/screenConstants";

export const isMobileState = (isMobile) => (dispatch) => {
    try {
        dispatch({
            type: ISMOBILE_UPDATE,
            payload: isMobile
        })
        
    } catch (error) {
        dispatch({
            type: "TOOLS_TOGGLESTATE_ERROR",
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}