export const Icons = {
    locked: [
        <path className="cls-1" key="locked-path-1" d="M18.66,11.05V6.16a6.16,6.16,0,0,0-12.32,0v4.89a5.8,5.8,0,0,0-4.6,5.67V25H23.26V16.72A5.8,5.8,0,0,0,18.66,11.05ZM12.5,2.15a4,4,0,0,1,4,4v4.77h-8V6.16A4,4,0,0,1,12.5,2.15Zm8.6,20.7H3.9V16.72a3.64,3.64,0,0,1,3.63-3.64h9.94a3.64,3.64,0,0,1,3.63,3.64Z" />
    ],
    user: [
        <path className="cls-1" key="user-path-1" d="M8,10A4.83,4.83,0,1,0,3.18,5.18,4.8,4.8,0,0,0,8,10Zm0-7.6A2.78,2.78,0,1,1,5.21,5.18,2.77,2.77,0,0,1,8,2.41Z" />,
        <path className="cls-1" key="user-path-2" d="M20,13.94H15.18a5.15,5.15,0,0,0-4.4-2.5H5.16A5.18,5.18,0,0,0,0,16.63v8H25V19A5,5,0,0,0,20,13.94ZM13.9,22.59H2v-6a3.13,3.13,0,0,1,3.12-3.14h5.62a3.13,3.13,0,0,1,3.12,3.14Zm9.06,0h-7v-6a4.35,4.35,0,0,0,0-.63H20A3,3,0,0,1,23,19Z" />,
        <path className="cls-1" key="user-path-3" d="M18.89,12.48a3.21,3.21,0,1,0-3.18-3.2A3.2,3.2,0,0,0,18.89,12.48Zm0-4.7a1.5,1.5,0,1,1-1.48,1.5A1.49,1.49,0,0,1,18.89,7.78Z" />
    ],
    bell: [
        <path className="cls-1" key="bell-path-1" d="M21.51,15.63V9.36a9,9,0,0,0-18,0v6.27L0,21.61H8.58a4.05,4.05,0,0,0,7.84,0H25Zm-7,3.95v1a2,2,0,0,1-4.06,0v-1H3.53l2-3.4V9.36a7,7,0,0,1,14,0v6.82l2,3.4Z" />
    ],
    profile: [
        <path className="cls-1" key="profile-path-1" d="M12.5,4.69a4.75,4.75,0,1,0,4.74,4.74A4.74,4.74,0,0,0,12.5,4.69Zm0,7.27A2.53,2.53,0,1,1,15,9.43,2.53,2.53,0,0,1,12.5,12Z" />,
        <path className="cls-1" key="profile-path-2" d="M20.76,0H4.24A4.24,4.24,0,0,0,0,4.24V20.76A4.24,4.24,0,0,0,4.24,25H20.76A4.24,4.24,0,0,0,25,20.76V4.24A4.24,4.24,0,0,0,20.76,0ZM18.1,22.83H6.9v-2a2.9,2.9,0,0,1,2.89-2.89h5.42A2.9,2.9,0,0,1,18.1,20.8Zm4.73-2.07a2.07,2.07,0,0,1-2.07,2.07h-.45v-2a5.11,5.11,0,0,0-5.1-5.1H9.79a5.11,5.11,0,0,0-5.1,5.1v2H4.24a2.07,2.07,0,0,1-2.07-2.07V4.24A2.07,2.07,0,0,1,4.24,2.17H20.76a2.07,2.07,0,0,1,2.07,2.07Z" />
    ],
    settings: [
        <path className="cls-1" key="settings-path-1" d="M24.86,8.92l-3-5.28L18.26,5.17A9.11,9.11,0,0,0,16,3.85L15.54,0H9.46L9,3.85A8.93,8.93,0,0,0,6.73,5.17L3.17,3.64l-3,5.28h0l3.11,2.31a9,9,0,0,0,0,2.54L.14,16.08h0l3,5.28h0l3.56-1.53A8.93,8.93,0,0,0,9,21.15L9.46,25h6.08L16,21.15a9.11,9.11,0,0,0,2.26-1.32l3.57,1.53,3-5.28h0l-3.12-2.32a10,10,0,0,0,0-2.52l3.12-2.32Zm-4.2.86-.87.64.15,1.06a7.47,7.47,0,0,1,0,2l-.15,1.06.87.64,1.82,1.35-1.42,2.48L19,18.16l-1-.42-.84.67a7.6,7.6,0,0,1-1.83,1.06l-1,.4-.13,1.07-.26,2.25H11.07l-.26-2.25-.13-1.07-1-.4a7.46,7.46,0,0,1-1.83-1.06L7,17.74l-1,.43-2.08.88L2.52,16.57l1.81-1.34.86-.64-.14-1.06a7.61,7.61,0,0,1,0-2.06l.14-1.06-.86-.64L2.52,8.43,3.94,6,6,6.83l1,.43.84-.67A7.46,7.46,0,0,1,9.68,5.53l1-.4.13-1.07.26-2.25h2.86l.26,2.25.13,1.07,1,.4a7.6,7.6,0,0,1,1.83,1.06l.84.67,1-.42L21.06,6l1.42,2.48Z" />,
        <circle className="cls-1" key="settings-circle-1" cx="12.5" cy="12.5" r="3.31" />
    ],
    search: [
        <path className="cls-1" key="search-path-1" d="M24.76,22,18.18,15.4a9.88,9.88,0,1,0-3.13,2.91L21.74,25ZM2.37,9.83a7.7,7.7,0,1,1,7.7,7.7A7.72,7.72,0,0,1,2.37,9.83Z" />
    ],
    wifi: [
        <path className="cls-1" key="wifi-path-1" d="M23.23,12.86a14.07,14.07,0,0,0-21.46,0L0,11.36a16.39,16.39,0,0,1,25,0Z" />,
        <path className="cls-1" key="wifi-path-2" d="M5.49,16.52,3.66,15.09a11.2,11.2,0,0,1,17.68,0l-1.83,1.43a8.88,8.88,0,0,0-14,0Z" />,
        <path className="cls-1" key="wifi-path-3" d="M16.47,18.59a4.55,4.55,0,0,0-7.94,0v.84h7.94Z" />
    ],
    variable: [
        <path className="cls-1" key="variable-path-1" d="M20.25,5.72v1.5H5.47V5.36H0v5.47H5.47V9a3.92,3.92,0,0,1,3.42,2.72l1,3.22a4.94,4.94,0,0,0,4.73,3.44h1.05v1.27H20V15.36H15.71v1.26H14.66a3.2,3.2,0,0,1-3.06-2.23l-1-3.22A5.76,5.76,0,0,0,9.25,9h11v1.5H25V5.72Z" />,
        <path className="cls-1" key="variable-path-2" d="M20.25,5.72v1.5H5.47V5.36H0v5.47H5.47V9a3.92,3.92,0,0,1,3.42,2.72l1,3.22a4.94,4.94,0,0,0,4.73,3.44h1.05v1.27H20V15.36H15.71v1.26H14.66a3.2,3.2,0,0,1-3.06-2.23l-1-3.22A5.76,5.76,0,0,0,9.25,9h11v1.5H25V5.72Z" />
    ],
    store: [
        <path className="cls-1" key="store-path-1" d="M22.75,2.58a2.78,2.78,0,0,0-2.6-1.8H4.85a2.78,2.78,0,0,0-2.6,1.8L0,8.6v0a3.91,3.91,0,0,0,1.35,3V24.22h22.3V11.59a3.91,3.91,0,0,0,1.35-3v0Zm-2.66-.15a1.13,1.13,0,0,1,1,.71l1.7,4.39H19l-.94-5.1Zm3.25,6.41a2.09,2.09,0,0,1-4.17-.07h4.14Zm-21.68,0,0-.07H5.83a2.09,2.09,0,0,1-4.17.07ZM17.5,8.77a2.09,2.09,0,0,1-4.17,0ZM16.39,2.43l.94,5.1h-4V2.43Zm-4.72,0v5.1h-4l.95-5.1Zm0,6.34a2.09,2.09,0,0,1-4.17,0ZM3.87,3.14a1.13,1.13,0,0,1,1-.71h2L6,7.53H2.17Zm6.56,19V15H5.83v7.14H3.42v-9.7a3.73,3.73,0,0,0,3.24-1.38,3.75,3.75,0,0,0,5.84,0,3.75,3.75,0,0,0,5.84,0,3.73,3.73,0,0,0,3.24,1.38v9.7Z" />,
        <rect className="cls-1" key="store-rect-1" x="13.6" y="15.3" width="5.12" height="4.6" />
    ],
    userform: [
        <path className="cls-1" key="userform-path-1" d="M13.58,11.45l-2.24,1a6.06,6.06,0,0,0-1.1-.64L10,9.35H5.72l-.28,2.41a6.15,6.15,0,0,0-1.11.64l-2.1-.9-.12-.05L0,15.12l2,1.44c0,.21,0,.42,0,.62s0,.4,0,.61L0,19.23,2.11,22.9,4.34,22a5.45,5.45,0,0,0,1.1.64L5.72,25H10l.28-2.41a5.74,5.74,0,0,0,1.1-.64l2.12.9.12,0,2.1-3.67-1.95-1.45c0-.2,0-.41,0-.6s0-.4,0-.61l1.95-1.45ZM12.26,16l.07.54a4.75,4.75,0,0,1,0,1.23l-.07.54,1.6,1.19-.95,1.67-1.84-.79-.43.34a4.86,4.86,0,0,1-1.1.64l-.5.2-.24,2H6.88l-.23-2-.51-.21A4.33,4.33,0,0,1,5,20.74l-.43-.33-1.83.78-1-1.67,1.6-1.19-.08-.53c0-.22,0-.43,0-.62s0-.41,0-.62L3.42,16l-1.6-1.19,1-1.67L4.61,14,5,13.61A4.77,4.77,0,0,1,6.14,13l.51-.2.23-2H8.8l.24,2,.5.2a4.86,4.86,0,0,1,1.1.64l.43.34,1.84-.79.95,1.67Z" />,
        <path className="cls-1" key="userform-path-2" d="M7.6,15.29a2.08,2.08,0,1,0,2.08,2.08A2.09,2.09,0,0,0,7.6,15.29Z" />,
        <path className="cls-1" key="userform-path-3" d="M20.1,0H4.9A4.91,4.91,0,0,0,0,4.9V9.32H2.24V4.9A2.66,2.66,0,0,1,4.9,2.24H20.1A2.66,2.66,0,0,1,22.76,4.9V20.1a2.66,2.66,0,0,1-2.66,2.66H16.23V25H20.1A4.91,4.91,0,0,0,25,20.1V4.9A4.91,4.91,0,0,0,20.1,0Z" />,
        <rect className="cls-1" key="userform-rect-1" x="7.95" y="4.73" width="9.58" height="2.08" />
    ],
    share: [
        <path className="cls-1" key="share-path-1" d="M20.33,21a1.73,1.73,0,0,1-1.73,1.74H4A1.74,1.74,0,0,1,2.26,21V6.4A1.73,1.73,0,0,1,4,4.67h9.78V2.4H4a4,4,0,0,0-4,4V21a4,4,0,0,0,4,4H18.6a4,4,0,0,0,4-4V12.21H20.33Z" />,
        <polygon className="cls-1" key="share-polygon-1" points="23.06 0 16.92 0 16.92 1.94 21.3 1.94 9.13 14.11 10.96 15.94 23.06 3.84 23.06 8.08 25 8.08 25 1.94 25 0 23.06 0" />
    ],
    move: [
        <path className="cls-1" key="move-path-1" d="M14,22.25a2.1,2.1,0,0,1-.41.31V16.08H11.43v6.48a2.1,2.1,0,0,1-.41-.31l-2.4-2.4L7.11,21.36l2.39,2.4a4.26,4.26,0,0,0,6,0l2.39-2.4-1.51-1.51Z" />,
        <path className="cls-1" key="move-path-2" d="M11,2.75a2.1,2.1,0,0,1,.41-.31V8.92h2.14V2.44a2.1,2.1,0,0,1,.41.31l2.4,2.4,1.51-1.51L15.5,1.24a4.26,4.26,0,0,0-6,0L7.11,3.64,8.62,5.15Z" />,
        <path className="cls-1" key="move-path-3" d="M23.76,9.5l-2.4-2.39L19.85,8.62l2.4,2.4a2.1,2.1,0,0,1,.31.41H16.08v2.14h6.48a2.1,2.1,0,0,1-.31.41l-2.4,2.4,1.51,1.51,2.4-2.39A4.26,4.26,0,0,0,23.76,9.5Z" />,
        <path className="cls-1" key="move-path-4" d="M2.44,13.57H8.92V11.43H2.44A2.1,2.1,0,0,1,2.75,11l2.4-2.4L3.64,7.11,1.24,9.5a4.26,4.26,0,0,0,0,6l2.4,2.39,1.51-1.51L2.75,14A2.1,2.1,0,0,1,2.44,13.57Z" />
    ],
    remove: [
        <path className="cls-1" key="remove-path-1" d="M8.54,17.82v5H4.26a2.12,2.12,0,0,1-2.12-2.12V17.82h6.4m2.15-2.15H0v5.07A4.26,4.26,0,0,0,4.26,25h6.43V15.67Z" />,
        <path className="cls-1" key="remove-path-2" d="M8.54,2.14v8.58H2.14V4.26A2.12,2.12,0,0,1,4.26,2.14H8.54M10.69,0H4.26A4.26,4.26,0,0,0,0,4.26v8.6H10.69V0Z" />,
        <path className="cls-1" key="remove-path-3" d="M20.74,2.14a2.12,2.12,0,0,1,2.12,2.12V8.51H15.64V2.14h5.1m0-2.14H13.5V10.65H25V4.26A4.26,4.26,0,0,0,20.74,0Z" />,
        <polygon className="cls-1" key="remove-polygon-1" points="25 15.22 23.27 13.49 19.25 17.52 15.22 13.49 13.49 15.22 17.52 19.25 13.49 23.27 15.22 25 19.25 20.97 23.27 25 25 23.27 20.97 19.25 25 15.22" />
    ],
    support: [
        <path className="cls-1" key="support-path-1" d="M12.5,0A12.5,12.5,0,1,0,25,12.5,12.5,12.5,0,0,0,12.5,0Zm6.68,4.41L16,7.6a6,6,0,0,0-7,0L5.82,4.41a10.46,10.46,0,0,1,13.36,0ZM8.48,12.5a4,4,0,1,1,4,4A4,4,0,0,1,8.48,12.5ZM4.41,5.82,7.6,9a6,6,0,0,0,0,7L4.41,19.18a10.46,10.46,0,0,1,0-13.36ZM5.82,20.59,9,17.4a6,6,0,0,0,7,0l3.19,3.19a10.46,10.46,0,0,1-13.36,0Zm14.77-1.41L17.4,16a6,6,0,0,0,0-7l3.19-3.19a10.46,10.46,0,0,1,0,13.36Z" />
    ],
    organizations: [
        <path className="cls-1" key="organizations-path-1" d="M5.83,17.94H2.89A2.89,2.89,0,0,0,0,20.83V25H8.71V20.83A2.89,2.89,0,0,0,5.83,17.94ZM1.44,20.83a1.44,1.44,0,0,1,1.45-1.44H5.83a1.44,1.44,0,0,1,1.44,1.44v2.71H1.44Z" />,
        <path className="cls-1" key="organizations-path-2" d="M4.36,17a2.7,2.7,0,1,0-2.7-2.69A2.7,2.7,0,0,0,4.36,17Zm0-3.94a1.25,1.25,0,1,1-1.25,1.25A1.25,1.25,0,0,1,4.36,13.08Z" />,
        <path className="cls-1" key="organizations-path-3" d="M22.11,17.94H19.17a2.89,2.89,0,0,0-2.88,2.89V25H25V20.83A2.9,2.9,0,0,0,22.11,17.94Zm-4.38,5.6V20.83a1.44,1.44,0,0,1,1.44-1.44h2.94a1.44,1.44,0,0,1,1.45,1.44v2.71Z" />,
        <path className="cls-1" key="organizations-path-4" d="M20.64,17A2.7,2.7,0,1,0,18,14.33,2.7,2.7,0,0,0,20.64,17Zm-1.25-2.69a1.25,1.25,0,1,1,1.25,1.25A1.25,1.25,0,0,1,19.39,14.33Z" />,
        <path className="cls-1" key="organizations-path-5" d="M16.86,9.21A2.9,2.9,0,0,0,14,6.32H11A2.9,2.9,0,0,0,8.14,9.21v4.16h8.72ZM9.59,11.92V9.21A1.44,1.44,0,0,1,11,7.76H14a1.44,1.44,0,0,1,1.44,1.45v2.71Z" />,
        <path className="cls-1" key="organizations-path-6" d="M12.5,5.4A2.7,2.7,0,1,0,9.81,2.71,2.69,2.69,0,0,0,12.5,5.4ZM11.25,2.71A1.25,1.25,0,1,1,12.5,4,1.25,1.25,0,0,1,11.25,2.71Z" />
    ],
    plus: [
        <polygon className="cls-1" key="plus-polygon-1" points="25 11.07 13.93 11.07 13.93 0 11.07 0 11.07 11.07 0 11.07 0 13.93 11.07 13.93 11.07 25 13.93 25 13.93 13.93 25 13.93 25 11.07" />
    ],
    minus: [
        <rect className="cls-1" key="minus-rect-1" y="10.98" width="25" height="3.04" />
    ],
    success: [
        <polygon className="cls-1" key="success-polygon-1" points="20.7 8.59 18.9 6.79 11.08 14.61 6.1 9.63 4.3 11.43 11.08 18.21 12.88 16.41 12.88 16.41 20.7 8.59" />
    ],
    warning: [
        <rect className="cls-1" key="warning-rect-1" x="11.14" y="4.08" width="2.73" height="11.48" />,
        <rect className="cls-1" key="warning-rect-2" x="11.08" y="18.07" width="2.84" height="2.84" />,
        <rect className="cls-1" key="warning-rect-3" width="25" height="25" />
    ],
    unlocked: [
        <path className="cls-1" key="unlocked-path-1" d="M18.66,11.05V6.16a6.16,6.16,0,0,0-12.32,0H8.5a4,4,0,1,1,8,0v4.77h-9a5.79,5.79,0,0,0-5.79,5.79V25H23.26V16.72A5.8,5.8,0,0,0,18.66,11.05Zm2.44,11.8H3.9V16.72a3.64,3.64,0,0,1,3.63-3.64h9.94a3.64,3.64,0,0,1,3.63,3.64Z" />
    ],
    error: [
        <polygon className="cls-1" key="error-polygon-1" points="18.35 8.27 16.73 6.65 12.5 10.88 8.27 6.65 6.65 8.27 10.88 12.5 6.65 16.73 8.27 18.35 12.5 14.12 16.73 18.35 18.35 16.73 14.12 12.5 18.35 8.27" />
    ],
    dashboard: [
        <path className="cls-1" key="dashboard-path-1" d="M8.54,17.82v5H4.26a2.12,2.12,0,0,1-2.12-2.12V17.82h6.4m2.15-2.15H0v5.07A4.26,4.26,0,0,0,4.26,25h6.43V15.67Z" />,
        <path className="cls-1" key="dashboard-path-2" d="M8.54,2.14v8.58H2.14V4.26A2.12,2.12,0,0,1,4.26,2.14H8.54M10.69,0H4.26A4.26,4.26,0,0,0,0,4.26v8.6H10.69V0Z" />,
        <path className="cls-1" key="dashboard-path-3" d="M20.74,2.14a2.12,2.12,0,0,1,2.12,2.12V5.67H15.64V2.14h5.1m0-2.14H13.5V7.81H25V4.26A4.26,4.26,0,0,0,20.74,0Z" />,
        <path className="cls-1" key="dashboard-path-4" d="M22.86,12.77v8a2.12,2.12,0,0,1-2.12,2.12h-5.1V12.77h7.22M25,10.62H13.5V25h7.24A4.26,4.26,0,0,0,25,20.74V10.62Z" />
    ],
    youtube: [
        <path className="cls-1" key="youtube-path-1" d="M7.73,0h9.55A7.73,7.73,0,0,1,25,7.73v9.55A7.72,7.72,0,0,1,17.28,25H7.73A7.73,7.73,0,0,1,0,17.27V7.73A7.73,7.73,0,0,1,7.73,0Z" />,
        <polygon className="cls-1" key="youtube-polygon-1" points="9.72 16.38 9.72 8.62 16.41 12.5 9.72 16.38" />
    ],
    arrowup: [
        <path className="cls-1" key="arrowup-path-1" d="M10.66,10.85a2.6,2.6,0,0,1,3.68,0l6.8,6.8H25L16.27,8.91a5.34,5.34,0,0,0-7.54,0L0,17.65H3.86Z" />
    ],
    binopened: [
        <path className="cls-1" key="binopened-path-1" d="M5.46,21.44A3.57,3.57,0,0,0,9,25h7.55a3.57,3.57,0,0,0,3.56-3.56V9.7H5.46Zm1.76-10H18.37v10a1.8,1.8,0,0,1-1.8,1.79H9a1.8,1.8,0,0,1-1.8-1.79Z" />,
        <path className="cls-1" key="binopened-path-2" d="M20.06,3.18a2.83,2.83,0,0,0-3.44-2l-2,.52-.15-.59a1.43,1.43,0,0,0-1.73-1L9.56.85a1.43,1.43,0,0,0-1,1.73l.16.59-2,.52a2.83,2.83,0,0,0-2,3.44L5,8.74,20.47,4.79ZM10,1.74,12.79,1a.34.34,0,0,1,.41.24l.18.71L9.9,2.86l-.18-.71A.33.33,0,0,1,10,1.74ZM6.14,6.88l0-.13A1.29,1.29,0,0,1,7,5.17L17,2.62a1.29,1.29,0,0,1,1.58.94l0,.13Z" />,
        <rect className="cls-1" key="binopened-rect-1" x="8.95" y="13.51" width="1.49" height="7.35" />,
        <rect className="cls-1" key="binopened-rect-2" x="12.05" y="13.51" width="1.49" height="7.35" />,
        <rect className="cls-1" key="binopened-rect-3" x="15.15" y="13.51" width="1.49" height="7.35" />
    ],
    arrowleft: [
        <path className="cls-1" key="arrowleft-path-1" d="M10.85,14.34a2.6,2.6,0,0,1,0-3.68l6.8-6.8V0L8.91,8.73a5.34,5.34,0,0,0,0,7.54L17.65,25V21.14Z" />
    ],
    bin: [
        <path className="cls-1" key="bin-path-1" d="M22.78,6.27a3.65,3.65,0,0,0-3.65-3.65h-2.7V1.83A1.83,1.83,0,0,0,14.6,0H10.4A1.83,1.83,0,0,0,8.57,1.83v.79H5.87A3.65,3.65,0,0,0,2.22,6.27V8.41H3.56V20.66A4.35,4.35,0,0,0,7.9,25h9.2a4.35,4.35,0,0,0,4.34-4.34V8.41h1.34ZM19.29,20.66a2.2,2.2,0,0,1-2.19,2.19H7.9a2.2,2.2,0,0,1-2.19-2.19V8.5H19.29ZM10.18,1.84a.49.49,0,0,1,.5-.49h3.64a.49.49,0,0,1,.5.49v.78H10.18ZM5.87,4.59H19.13A1.68,1.68,0,0,1,20.8,6.27v.08H4.2V6.27A1.68,1.68,0,0,1,5.87,4.59Z" />,
        <rect className="cls-1" key="bin-rect-1" x="7.81" y="11" width="1.82" height="8.96" />,
        <rect className="cls-1" key="bin-rect-2" x="11.59" y="11" width="1.82" height="8.96" />,
        <rect className="cls-1" key="bin-rect-3" x="15.37" y="11" width="1.82" height="8.96" />
    ],
    copy: [
        <path className="cls-1" key="copy-path-1" d="M15.24,18.66v2.65l3.37-3.37a2.37,2.37,0,0,0,0-3.36l-3.37-3.37v2.64l1.28,1.26H3.3a1,1,0,0,1-1-1V3.3a1,1,0,0,1,1-1h11a1,1,0,0,1,1,1V5.73h2.3V3.3A3.3,3.3,0,0,0,14.28,0H3.3A3.3,3.3,0,0,0,0,3.3V14.11a3.3,3.3,0,0,0,3.3,3.3H16.52Z" />,
        <path className="cls-1" key="copy-path-2" d="M21.7,7.42h-11a3.3,3.3,0,0,0-3.3,3.3v1.93h2.3V10.72a1,1,0,0,1,1-1h11a1,1,0,0,1,1,1v11a1,1,0,0,1-1,1h-11a1,1,0,0,1-1-1V19.42H7.42V21.7a3.3,3.3,0,0,0,3.3,3.3h11A3.3,3.3,0,0,0,25,21.7v-11A3.3,3.3,0,0,0,21.7,7.42Z" />
    ],
    arrowright: [
        <path className="cls-1" key="arrowright-path-1" d="M14.15,10.66a2.6,2.6,0,0,1,0,3.68l-6.8,6.8V25l8.74-8.73a5.34,5.34,0,0,0,0-7.54L7.35,0V3.86Z" />
    ],
    arrowdown: [
        <path className="cls-1" key="arrowdown-path-1" d="M14.34,14.15a2.6,2.6,0,0,1-3.68,0l-6.8-6.8H0l8.73,8.74a5.34,5.34,0,0,0,7.54,0L25,7.35H21.14Z" />
    ],
    agro: [
        <path className="cls-1" key="agro-path-1" d="M22.68,15.45v-1.3a3.59,3.59,0,0,0-3.59-3.59H19V8.26h-.88V7.68l1-1-.52-.52L17.38,7.38v.88H16.5v2.3H14.67l-2-5.28a1.54,1.54,0,0,0-1.45-1H4.38A1.54,1.54,0,0,0,2.83,5.83v7a4.8,4.8,0,1,0,6.31,6.44h9.22a3.35,3.35,0,1,0,4.32-3.79ZM17.23,9h1v1.57h-1ZM8.09,5.5h3.07a.48.48,0,0,1,.45.32l1.73,4.74H8.09ZM4.05,6a.49.49,0,0,1,.49-.48H6.87v5.06H4.05ZM8.38,17.51l0,.2a.36.36,0,0,1,0,.11,1.31,1.31,0,0,1-.06.27v0c0,.1-.06.2-.1.3s0,0,0,0l0,.1a3.59,3.59,0,1,1-4-4.91h0l.1,0,.3,0h.29A3.59,3.59,0,0,1,8.4,17.18C8.4,17.29,8.39,17.4,8.38,17.51Zm11-1.3-.08.08a1.22,1.22,0,0,0-.15.17s0,0-.05.05a1.88,1.88,0,0,0-.18.24.21.21,0,0,0,0,.06l-.12.2a.93.93,0,0,0,0,.1l-.09.17s0,.08,0,.11a1.34,1.34,0,0,0-.07.18l0,.12a1.33,1.33,0,0,0-.05.19l0,.11v0H9.54c0-.05,0-.1,0-.14a2.42,2.42,0,0,0,0-.25,3.4,3.4,0,0,0,0-.45c0-.06,0-.11,0-.17h3.6v-.73H9.53a4.81,4.81,0,0,0-4.72-3.91l-.39,0-.27,0h-.1v-.63h15a2.37,2.37,0,0,1,2.26,1.67H19.94v.73h1.52v1.11h-.21l-.2,0-.11,0-.22.05-.08,0-.29.1-.05,0-.22.11-.09.05a1,1,0,0,0-.17.1l-.1.07-.15.11-.09.07Zm2.31,4.55a2.13,2.13,0,1,1,0-4.26h.16l.17,0h0a2.12,2.12,0,0,1-.36,4.22Z" />,
        <path className="cls-1" key="agro-path-2" d="M22.32,6.27a1.34,1.34,0,0,0,.26-.09,4.29,4.29,0,0,0,2.31-2.43c.09-.22.18-.46,0-.63s-.35-.1-.59,0a4.29,4.29,0,0,1-1.95.15C20.63,3,19.77,4.72,19.56,5.68l0,.2L19.7,6A3.61,3.61,0,0,0,22.32,6.27Zm-.4-1.89a2.42,2.42,0,0,0-.83.25,2.88,2.88,0,0,0-.72.47l-.1.1a2,2,0,0,1,2-1.45,4.52,4.52,0,0,0,2.07-.14,3.79,3.79,0,0,1-2,2.09,2.93,2.93,0,0,1-2.19-.08c.39-.17.75-.35,1.11-.53s.47-.22.71-.31a6.3,6.3,0,0,1,.77-.26A1.52,1.52,0,0,0,21.92,4.38Z" />,
        <rect className="cls-1" key="agro-rect-1" x="8.4" y="12.37" width="3.04" height="0.73" />,
        <rect className="cls-1" key="agro-rect-2" x="14.71" y="12.98" width="0.73" height="1.67" />,
        <rect className="cls-1" key="agro-rect-3" x="16.79" y="12.98" width="0.73" height="1.67" />
    ],
    xclose: [
        <polygon className="cls-1" key="xclose-polygon-1" points="25 2.87 22.14 0 12.5 9.63 2.87 0 0 2.87 9.63 12.5 0 22.14 2.87 25 12.5 15.37 22.14 25 25 22.14 15.37 12.5 25 2.87" />
    ],
    linkedin: [
        <path className="cls-1" key="linkedin-path-2" d="M15.71,9.36a3.55,3.55,0,0,0-3.47,1.88V9.44h-2v9.41h2v-5a2.62,2.62,0,0,1,2.67-2.56,2.09,2.09,0,0,1,2.26,2.28v5.29h2V13A3.29,3.29,0,0,0,15.71,9.36Z" />,
        <rect className="cls-1" key="linkedin-rect-1" x="5.79" y="6.15" width="2.02" height="2.05" />,
        <rect className="cls-1" key="linkedin-rect-2" x="5.79" y="9.44" width="2.02" height="9.41" />
    ],
    instagram: [
        <path className="cls-1" key="instagram-path-2" d="M15.32,5.44H9.79A4.29,4.29,0,0,0,5.5,9.73v5.54a4.29,4.29,0,0,0,4.29,4.29h5.53a4.29,4.29,0,0,0,4.29-4.29V9.73A4.29,4.29,0,0,0,15.32,5.44Zm3,9.81a3,3,0,0,1-3,3H9.8a3,3,0,0,1-3-3V9.75a3,3,0,0,1,3-3h5.51a3,3,0,0,1,3,3Z" />,
        <path className="cls-1" key="instagram-path-3" d="M12.55,8.93a3.57,3.57,0,1,0,3.58,3.57A3.57,3.57,0,0,0,12.55,8.93Zm0,5.81A2.24,2.24,0,1,1,14.8,12.5,2.24,2.24,0,0,1,12.55,14.74Z" />,
        <path className="cls-1" key="instagram-path-4" d="M16.21,8a.84.84,0,1,0,.84.84A.84.84,0,0,0,16.21,8Z" />
    ],
    event: [
        <path className="cls-1" key="event-path-1" d="M20.53,1.41h-1.9V0H16.54V1.41H8.46V0H6.37V1.41H4.47A4.13,4.13,0,0,0,.35,5.53V20.88A4.13,4.13,0,0,0,4.47,25H20.53a4.13,4.13,0,0,0,4.12-4.12V5.53A4.13,4.13,0,0,0,20.53,1.41Zm2,19.47a2,2,0,0,1-2,2H4.47a2,2,0,0,1-2-2V9.58H22.57Zm0-13.38H2.43v-2a2,2,0,0,1,2-2h1.9V5H8.46V3.5h8.08V5h2.09V3.5h1.9a2,2,0,0,1,2,2Z" />,
        <rect className="cls-1" key="event-rect-1" x="6.58" y="17.64" width="11.83" height="2.08" />,
        <rect className="cls-1" key="event-rect-2" x="6.58" y="12.78" width="11.83" height="2.08" />
    ],
    edit: [
        <path className="cls-1" key="edit-path-1" d="M10.66,19.46,9.59,19a7.06,7.06,0,0,1-1.74-1l-.91-.71L4,18.57,2.76,16.41l2.57-1.9-.15-1.14a6.55,6.55,0,0,1-.07-1,6.75,6.75,0,0,1,.07-1l.15-1.13L2.76,8.37,4,6.22,6.94,7.48l.91-.71a7.42,7.42,0,0,1,1.74-1l1.07-.43L11,2.14h4.16V0h-6l-.45,3.8A9.26,9.26,0,0,0,6.62,5L3.1,3.54,0,9l3.07,2.28A9.14,9.14,0,0,0,3,12.39a9.41,9.41,0,0,0,.07,1.17L0,15.83l3.1,5.42,3.52-1.51A9.1,9.1,0,0,0,8.71,21l.45,3.8h6V22.64H11Z" />,
        <path className="cls-1" key="edit-path-2" d="M23.23,0H20.16A1.77,1.77,0,0,0,18.39,1.8V17.93a.85.85,0,0,0,.06.31l2.3,6.06a1,1,0,0,0,1.89,0l2.3-6.06a.85.85,0,0,0,.06-.31V1.8A1.77,1.77,0,0,0,23.23,0Zm-.16,16H20.32V2h2.74ZM23,17.94,21.7,21.68l-1.32-3.74Z" />,
        <circle className="cls-1" key="edit-circle-1" cx="12.27" cy="12.39" r="3.23" />
    ],
    folder: [
        <path className="cls-1" key="folder-path-1" d="M24.64,12.59a2.51,2.51,0,0,0-2.18-1.24H20.51V8.56A3.54,3.54,0,0,0,17,5H10.74L8.93,2.24H3.53A3.54,3.54,0,0,0,0,5.77v17H20.52l4.17-7.66A2.53,2.53,0,0,0,24.64,12.59ZM3.53,4H8L9.77,6.81H17a1.75,1.75,0,0,1,1.75,1.75v2.79H8.59a4.39,4.39,0,0,0-3.87,2.3L1.79,19V5.77A1.74,1.74,0,0,1,3.53,4ZM23.12,14.25,19.46,21H2.76L6.29,14.5a2.63,2.63,0,0,1,2.3-1.37H22.46a.75.75,0,0,1,.65.37A.78.78,0,0,1,23.12,14.25Z" />
    ],
    industry: [
        <path className="cls-1" key="industry-path-1" d="M18,9.56v2.85L12.42,8.69l.05,7.23H10.76V14.35A1.41,1.41,0,0,0,9.41,13V7.07A1.53,1.53,0,0,0,7.88,5.54h-3A1.53,1.53,0,0,0,3.32,7.07V13A1.41,1.41,0,0,0,2,14.35v1.57H1.79A1.8,1.8,0,0,0,0,17.72V25H25V13.89ZM4.66,7.07a.18.18,0,0,1,.18-.18h3a.19.19,0,0,1,.19.18V13H4.66ZM3.32,14.35a.06.06,0,0,1,.05-.06h6a.06.06,0,0,1,.06.06v1.57H3.32Zm20.33,9.3H8.74V19.26H4.15v4.39H1.35V17.72a.45.45,0,0,1,.44-.45h12l0-6.06L18,14v3.26h1.39V12l4.3,2.66Z" />,
        <path className="cls-1" key="industry-path-2" d="M6.93,4h4.32a2.59,2.59,0,0,0,2.58-2.58V.68H12.48v.75a1.23,1.23,0,0,1-1.23,1.23H6.93Z" />,
        <path className="cls-1" key="industry-path-3" d="M4.49,2.58A1.23,1.23,0,0,1,5.72,1.35H10V0H5.72A2.58,2.58,0,0,0,3.14,2.58v.75H4.49Z" />,
        <rect className="cls-1" key="industry-rect-1" x="11.6" y="19.26" width="1.54" height="1.54" />,
        <rect className="cls-1" key="industry-rect-2" x="14.58" y="19.26" width="1.54" height="1.54" />,
        <rect className="cls-1" key="industry-rect-3" x="17.84" y="19.26" width="1.54" height="1.54" />,
        <rect className="cls-1" key="industry-rect-4" x="20.55" y="19.26" width="1.54" height="1.54" />
    ],
    home: [
        <path className="cls-1" key="home-path-1" d="M25,12,20.49,7.5V2.62A.61.61,0,0,0,19.87,2H18.42a.62.62,0,0,0-.62.62V4.81L15.19,2.2a3.81,3.81,0,0,0-5.39,0L0,12,1.39,13.4,2.77,12V23.92H22.23V12l1.41,1.4ZM20.54,22.22H15.3V14.38H9.7v7.84H4.46V10.3l6.7-6.74a1.9,1.9,0,0,1,2.67,0l6.71,6.71Z" />
    ],
    email: [
        <path className="cls-1" key="email-path-1" d="M20.14,7.85v0a.46.46,0,0,0-.07-.07.86.86,0,0,0-.13-.14l-.09-.08-.18-.12-.09,0-.23-.08-.08,0a1.86,1.86,0,0,0-.33,0H6A1.49,1.49,0,0,0,4.58,8.72v7.56a1.5,1.5,0,0,0,1.49,1.5H18.93a1.5,1.5,0,0,0,1.49-1.5V8.72A1.44,1.44,0,0,0,20.14,7.85Zm-1.95.61-5.56,3.39L6.87,8.46Zm1,7.82a.26.26,0,0,1-.26.26H6.07a.26.26,0,0,1-.26-.26v-7l6.83,4,6.55-4Z" />
    ],
    device: [
        <path className="cls-1" key="device-path-1" d="M22.77,6.4a4.11,4.11,0,0,0-4.11-4.11h-1V0H15.53V2.29H9.47V0H7.33V2.29H6.46A4.11,4.11,0,0,0,2.35,6.4v.93H0V9.47H2.35v6.06H0v2.14H2.35v.93a4.11,4.11,0,0,0,4.11,4.11h.87V25H9.47V22.71h6.06V25h2.14V22.71h1a4.11,4.11,0,0,0,4.11-4.11v-.93H25V15.53H22.77V9.47H25V7.33H22.77ZM20.63,18.6a2,2,0,0,1-2,2H6.46a2,2,0,0,1-2-2V6.4a2,2,0,0,1,2-2h12.2a2,2,0,0,1,2,2Z" />,
        <rect className="cls-1" key="device-rect-1" x="9.17" y="9.11" width="6.78" height="6.78" />
    ],
    fullscreen: [
        <path className="cls-1" key="fullscreen-path-1" d="M20.58,0H16.91V2.65h3.67a1.77,1.77,0,0,1,1.77,1.77V8.09H25V4.42A4.42,4.42,0,0,0,20.58,0Z" />,
        <path className="cls-1" key="fullscreen-path-2" d="M0,4.42V8.09H2.65V4.42A1.77,1.77,0,0,1,4.42,2.65H8.09V0H4.42A4.42,4.42,0,0,0,0,4.42Z" />,
        <path className="cls-1" key="fullscreen-path-3" d="M2.65,20.58V16.91H0v3.67A4.42,4.42,0,0,0,4.42,25H8.09V22.35H4.42A1.77,1.77,0,0,1,2.65,20.58Z" />,
        <path className="cls-1" key="fullscreen-path-4" d="M22.35,20.58a1.77,1.77,0,0,1-1.77,1.77H16.91V25h3.67A4.42,4.42,0,0,0,25,20.58V16.91H22.35Z" />
    ],
    logo: [
        <path className="cls-1" key="logo-path-1" d="M20.19,11.74V16.4H5.56a4.65,4.65,0,0,1-.68-1.71,4.28,4.28,0,0,1-.07-.81A4.33,4.33,0,0,1,4.9,13,4.62,4.62,0,0,1,9.44,9.25a4.37,4.37,0,0,1,.72.06v1.8A2.84,2.84,0,0,0,9.44,11a2.88,2.88,0,0,0-2.73,2,2.91,2.91,0,0,0-.14.89,3.11,3.11,0,0,0,.12.81H18.42V11.74a2.86,2.86,0,0,0-2.86-2.86H13.7V13H11.93V7.12h3.63a4.53,4.53,0,0,1,2.86,1,4.37,4.37,0,0,1,.78.77A4.61,4.61,0,0,1,20.19,11.74Z" />,
   ],
}