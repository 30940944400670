import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './FormInput.css'
import InputBox from '../../input/InputBox.js';
import { useTranslation } from "react-i18next";
import Title from '../../title/Title.js';
import Button from '../../buttonNav/Buttons';
import NiIcon from '../../niIcons/NiIcons';
import { flowForm, sendForm, startFlowForm } from '../../../redux/actions/infoActions';
import { useNavigate } from 'react-router-dom';
import { addAlertNotification } from '../../../redux/actions/alertAction';
import { addToastNotification } from '../../../redux/actions/toastAction';


export default function FormInput(props) {
  const dispatch = useDispatch()
  const { t } = useTranslation('common');
  const infoLoading = useSelector((state) => state.infoLoading)
  const saveFormFlow = useSelector((state) => state.saveFormFlow)
  const step = infoLoading.step
  const navigate = useNavigate();

  const setParam = ({ value, paramName }) => {
    dispatch(flowForm({ type: 'INFO_FLOWFORM_UPDATE', status: 'ENABLE', value: value, paramName: paramName, step: step }))
  }

  const handleChange = () => {
    const { fullName, companyName, email, whatsAppContact, age, category, area } = infoLoading.info
    /*  if (password === confirmPassword) {*/
    if (email.match(/@/)) {
      dispatch(addAlertNotification({
        hiddenCancel: true,
        confirmText: t('Account.AccountFlow.dataInput.button'),
        status: true,
        icon: 'bell',
        message: t('Account.AccountFlow.dataInput.Confirmation', { email: email }),
        actionConfirm: () => dispatch(sendForm({ fullName, companyName, email, whatsAppContact, age, category, area }))
      }))
    } else {
      dispatch(addToastNotification({
        icon: 'error',
        message: t('Account.AccountFlow.dataInput.InvalidEmail'),
        status: 'error'
      }))
    }
    /* } else {
       dispatch(addToastNotification({
         icon: 'success',
         message: t('Account.AccountFlow.dataInput.PasswordDoesntMatch'),
         status: 'success'
       }))
     }*/
  }


  useEffect(() => {
    if (saveFormFlow.completed) {
      dispatch(addAlertNotification({
        actionConfirm: () => {navigate('/'); dispatch(startFlowForm())},
        hiddenCancel: true,
        confirmText: "Ok",
        status: true,
        icon: 'logo',
        message: t('Account.AccountFlow.resgisterSuccess')
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveFormFlow.completed])


  return (
    <div className='FormInputBody'>
      <Title
        title={props?.title}
        marginBottom={10}
        fontWeight={700}
        fontSize={28}
        color={'black'}
      />
      <div className='FormItemsInputFlow'>
        <div className='JustInputs'>
          {/* <InputBox
          placeholder={t('Account.PlaceholderCompanyName')}
          inputTextAlign={'left'}
          width={'312px'}
          value={infoLoading.info.companyName}
          inputFontSize={14}
          padding={12}
          paramName={'companyName'}
          setValue={(e) => setParam({ value: e.value, paramName: e.paramName })}
  />*/}
          <InputBox
            placeholder={t('Account.PlaceholderFullName')}
            inputTextAlign={'left'}
            width={'312px'}
            inputFontSize={18}
            padding={12}
            value={infoLoading.info.fullName}
            paramName={'fullName'}
            setValue={(e) => setParam({ value: e.value, paramName: e.paramName })}
          />
          <InputBox
            placeholder={t('Account.PlaceholderWhatsApp')}
            inputTextAlign={'left'}
            width={'312px'}
            inputFontSize={18}
            padding={12}
            value={infoLoading.info.whatsAppContact}
            paramName={'whatsAppContact'}
            setValue={(e) => setParam({ value: e.value, paramName: e.paramName })}
          /><InputBox
            placeholder={t('Account.PlaceholderEmail')}
            type={'email'}
            inputTextAlign={'left'}
            width={'312px'}
            inputFontSize={18}
            padding={12}
            value={infoLoading.info.email}
            paramName={'email'}
            setValue={(e) => setParam({ value: e.value, paramName: e.paramName })}
          />
          {/* 
        <InputBox
          placeholder={t('Account.PlaceholderPassword')}
          type={'password'}
          inputTextAlign={'left'}
          width={'312px'}
          inputFontSize={14}
          padding={12}
          value={infoLoading.info.password}
          paramName={'password'}
          setValue={(e) => setParam({ value: e.value, paramName: e.paramName })}
        />*/}
          {/*<InputBox
          placeholder={t('Account.PlaceholderConfirmPassword')}
          type={'password'}
          inputTextAlign={'left'}
          width={'312px'}
          inputFontSize={14}
          padding={12}
          value={infoLoading.info.confirmPassword}
          paramName={'confirmPassword'}
          setValue={(e) => setParam({ value: e.value, paramName: e.paramName })}
        />*/}
        </div>
        <div className='InputButtons'>
          {/* <CheckBox
            style={{
              width: '330px', fontSize: "14px", color: "#797979",
              display: "flex",
              flexDirection: "row",
              gap: "20px"
            }}
            checked={agreement}
            onChange={setAgreement}
            Label={<Trans i18nKey={t('Account.AccountFlow.dataInput.Agreement')}
              components={{
                link1: <Link to={`/policy`} className="" />,
                link2: <Link to={`/term`} className="" />
              }} />} />*/}
          <Button
            Type={step === 4 ? 'DefaultColor' : 'Back'}
            text={step === 4 ? t('components.buttons.register') : t('components.buttons.back')}
            onClicks={step === 4 && handleChange}
          />
        </div>
      </div>
      <div className='IconLogoAccountInput'>
        <NiIcon
          name={'logo'}
          style={{ width: '45px', height: '25px', scale: '2', fill: '#265FFF' }}
          styleIcon={{ minX: "0", minY: "0", width: "25", height: "25" }}
        />
      </div>
    </div>
  )
}
