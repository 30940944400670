import React from 'react';
import Button from '../../components/buttonNav/Buttons';
import './Steps.css';
import sideImage from '../../images/LoftWoman.gif';
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


export default function Digitalization() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const isMobile = useSelector((state) => state.isMobile)
  return (
    <div className={`Steps ${!isMobile ? "desktop" : ""}`}>
      <div className={`${isMobile ? "MobileSteps" : "leftBox"}`}>
        <h1>
          <Trans i18nKey={t('Screen.Home.Step2.H1')}
            components={{
              highLight: <span style={{ color: '#265FFF' }} />
            }} />
        </h1>
        <h2>
          {t('Screen.Home.Step2.H2')}
        </h2>
        <div>
        <h3>
          {t('Screen.Home.Step2.H3')}
        </h3>
        <Button
          marginTop={26}
          Size={'Large'}
          Type={'DefaultColor'}
          text={`${t('Screen.Home.Step2.TextButton')}`}
          onClicks={() => navigate('/account')}
          justifyContent={isMobile ? "center" : "start"}
        />
        </div>
      </div>
      {!isMobile &&
        <div className='ImagesStep2'>
          <img src={sideImage} alt='Gif light' style={{ height: '74vh', transformOrigin: 'left center', margin: '5px' }} />
        </div>
      }
    </div>
  )
}
