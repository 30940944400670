import React from 'react';
import './AccountFlow.css'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../images/LogotipoBeta.svg';
import { useTranslation } from "react-i18next";
import FormOptions from '../../../components/FormComponent/FormOptions/FormOptions'
import FormComponent from '../../../components/FormComponent/FormComponent'
import FormInput from '../../../components/FormComponent/formInput/FormInput';
import { useSelector } from 'react-redux';
import AgeGroup  from '../../../images/Icons/AgeGroup.svg';
import Goal from '../../../images/Icons/Goal.svg';
import Register from '../../../images/Icons/Register.svg';
import Area from '../../../images/Icons/Area.svg';
import Person from '../../../images/Icons/Person.svg';
import Academy from '../../../images/Icons/Academic.svg';
import Professional from '../../../images/Icons/Professional.svg';
import Merchant from '../../../images/Icons/Merchant.svg';
import Industries from '../../../images/Icons/Industry.svg';
import Home from '../../../images/Icons/Home.svg';
import Agro from '../../../images/Icons/Agro.svg';
import Others from '../../../images/Icons/Others.svg';
import ProgressBar from '../../../components/progressBar/ProgressBar';
import age18 from '../../../images/Icons/-18.svg';
import age24 from '../../../images/Icons/18-24.svg';
import age30 from '../../../images/Icons/25-30.svg';
import age40 from '../../../images/Icons/31-40.svg';
import ageOver40 from '../../../images/Icons/+40.svg';





export default function AccountFlow(props) {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const info = useSelector((state) => state.infoLoading)
  const step = info.step
  
  const dataOptionsYears = [
    {
      label: t('Account.AccountFlow.dataOptionsYears.Young'),
      icon: <img src={age18} height={'49px'} alt='age18' />
    },
    {
      label: '18 - 24',
      icon: <img src={age24} height={'49px'} alt='age24' />
    },
    {
      label: '25 - 30',
      icon: <img src={age30} height={'49px'} alt='age30' /> 
    },
    {
      label: '31 - 40',
      icon: <img src={age40} height={'49px'} alt='age40' />
    },
    {
      label: t('Account.AccountFlow.dataOptionsYears.Old'),
      icon: <img src={ageOver40} height={'49px'} alt='ageOver40' />
    },

  ]

  const dataOptionsObj = [
    {
      label: t('Account.AccountFlow.dataOptionsObj.Personal'),
      icon: <img src={Person} height={'49px'} alt='Person' />
    },
    {
      label: t('Account.AccountFlow.dataOptionsObj.Academy'),
      icon: <img src={Academy} height={'49px'} alt='Academy' />
    },
    {
      label: t('Account.AccountFlow.dataOptionsObj.Corporation'),
      icon: <img src={Professional} height={'49px'} alt='Professional' />
    }
  ]


  const dataOptionsNeed = [
    {
      label: t('Account.AccountFlow.dataOptionsNeed.Home'),
      icon: <img src={Home} height={'49px'} alt='Home' />
    },
    {
      label: t('Account.AccountFlow.dataOptionsNeed.Industries'),
      icon: <img src={Industries} height={'49px'} alt='Industries' />
    },
    {
      label: t('Account.AccountFlow.dataOptionsNeed.Business'),
      icon: <img src={Merchant} height={'49px'} alt='Merchant' />
    },
    {
      label: t('Account.AccountFlow.dataOptionsNeed.Agribusiness'),
      icon: <img src={Agro} height={'49px'} alt='Agro' />
    },
    {
      label: t('Account.AccountFlow.dataOptionsNeed.Others'),
      icon: <img src={Others} height={'49px'} alt='Others' />
    }
  ]


  const dataSteps = [
    { 
      paramName: "age",
      icon: AgeGroup
    },
    {
      paramName: "category",
      icon: Goal
    },
    {
      paramName: "area",
      icon: Area
    },
    {
      paramName: "",
      icon: Register
    },
    
  ]

  return (
    <div className={`AccountFlowBody ${props.isMobile && 'AccountFlowMobile'}`}>
      {!props.isMobile &&
        <div className='LogoAccount'>
          <Logo style={{ cursor: 'pointer' }} width={'186px'} onClick={() => navigate('/')} />
        </div>
      }
      <div className={`RightAccount ${props.isMobile && 'MobileAccount'}`} >
        <FormComponent isMobile={props.isMobile}>
          <div className='ProgressBarFlow'>
            <ProgressBar formInfo={info} dataSteps={dataSteps} />
          </div>
          {
            step === 1 &&
            <FormOptions
              title={t('Account.AccountFlow.dataOptionsYears.Title')}
              AlignItems="center"
              dataOptions={dataOptionsYears}
              paramName="age"
            />
          }
          {
            step === 2 &&
            <FormOptions
              title={t('Account.AccountFlow.dataOptionsObj.Title')}
              height={'65px'}
              paramName="category"
              dataOptions={dataOptionsObj}
            />
          }
          {
            step === 3 &&
            <FormOptions
              title={t('Account.AccountFlow.dataOptionsNeed.Title')}
              dataOptions={dataOptionsNeed}
              paramName="area"
            />
          }
          {
            step === 4 &&
            <FormInput
              title={t('Account.AccountFlow.dataInput.Title')}
            />
          }
        </FormComponent>
      </div>
    </div>
  )
}