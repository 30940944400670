import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './Footer.css';
import PeoplesFooter from '../../images/PeopleFooter.png';
import Flag from '../../images/Flag_brasil_map.svg'
import Button from '../buttonNav/Buttons';
import ListLink from '../listLinks/ListLink';
import SocialIcons from '../socialIcons/SocialIcons';
import TextAnime from '../textAnime/TextAnime';
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function Footer() {
  const navigate = useNavigate();
  const infoToolsState = useSelector((state) => state.toolsToggleState)
  const infoStep = infoToolsState?.info
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const imagePeopleFooter = PeoplesFooter
  const { t } = useTranslation('common');

  const dataTextAnime = (t('Footer.animeText.dynamic',{returnObjects: true}))
  const staticTextAnime =(
    <div style={{ fontSize: '26px' }}>
       <Trans i18nKey={t('Footer.animeText.static')}
            components={{
              Num: <span style={{fontWeight: '900', color: '#39496D' }} />,
              Niot: <span style={{fontWeight: '900', color: '#265FFF' }} />
            }} />
    </div>)

  const dataLinkNav = [
    {
      Label: t('Footer.links.home'),
      Link: () => navigate('/')
    }, {
      Label: t('Footer.links.about'),
      Link: () => navigate('/about')
    }, {
      Label: t('Footer.links.blog'),
      Link: () => navigate('/blog')
    }
  ]

  const dataLinkHelp = [
    {
      Label: t('Footer.links.register'),
      Link: () => navigate('/account')
    }, {
      Label: t('Footer.links.plans'),
      Link: () => navigate('/plans')
    }, {
      Label: t('Footer.links.beta'),
      Link: () => navigate('/account')
    }
  ]
  const dataSocialIcons = [
    {
      Name: 'instagram',
      Title: '@nodeiotbr',
      Link: () => window.open("https://www.instagram.com/nodeiotbr/", "_blank")
    }, {
      Name: 'linkedin',
      Title: '@NodeIOT',
      Link: () => window.open("https://www.linkedin.com/company/nodeiot/about/", "_blank")
    }, {
      Name: 'email',
      Title: 'contato@nodeiot.com.br',
      Link: () => navigate('/contact')
    }
  ]

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <div className='FooterBody'>
      <div className='LinksFooter' >
        <SocialIcons
          Size='Ico'
          dataSocialIcons={dataSocialIcons}
        />
        <ListLink
          Title={t('Footer.aboutUs')}
          dataListLinks={dataLinkNav}
        />
        <ListLink
          Title={t('Footer.needHelp')}
          dataListLinks={dataLinkHelp}
        />
        <div className='ButtonFlag'>
          <Button
            Size={'Medium'}
            Type={'CancelB DefaultButton'}
            marginLeft={30}
            alignItems={'start'}
            text={t('Footer.Button')}
            onClicks={() => navigate('/account')}
          >
          </Button>
        </div>
        <div className='CopyRightFooter'>
            <div className='Flag'>
            <img src={Flag} width={'30px'} height={'30px'} />
            </div>
            <span className='TextFlag'> {t('Footer.Phrase')} </span>
            <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{t('Footer.Address')} | {t('Footer.CopyRight')}</span>
          </div>
          </div>
          
      </div>
      <div className='ImgFooter' style={{ width: windowDimensions?.width / 2.5, marginRight: windowDimensions?.width * 0.01 }}>
        <img src={imagePeopleFooter} style={{ transform: `scale(${windowDimensions?.width / 2680})`, transformOrigin: 'right bottom' }} />
        <TextAnime
          dataTextAnime={dataTextAnime}
          Text={staticTextAnime}
        />
      </div>
    </div>
  )
}