
import {
    ADD_TOAST_FAIL,
    ADD_TOAST_NOTIFICATION,
    REMOVE_TOAST_NOTIFICATION
} from "../constants/toastConstants";


export const addToastNotification = ({icon, message, status}) => (dispatch) => {
    try {
        dispatch({
            type: ADD_TOAST_NOTIFICATION,
            payload: {
                icon, 
                message, 
                status 
            }
        })

    } catch (error) {
        dispatch({
            type: ADD_TOAST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const removeToastAction = ( index ) => (dispatch) => {
    try {
        dispatch({
            type: REMOVE_TOAST_NOTIFICATION,
            payload: {index: index}
        })

    } catch (error) {
        dispatch({
            type: ADD_TOAST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}
