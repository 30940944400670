import React from 'react';
import './Steps.css';
import Cards from '../../images/DemonstrationTop.png';
import { useSelector } from 'react-redux';
import Logo from '../../images/Slogan.png'
import { Trans, useTranslation } from 'react-i18next';
import DashboardDemo from '../../images/DashboardRunning.gif'

export default function Demonstration() {

  const { t } = useTranslation('common');
  const isMobile = useSelector((state) => state.isMobile)
  return (
    <div className={isMobile ? 'MobileStepMain' : 'StepMain'}>
      <div className={isMobile ? 'CardMobile' : 'Card'}>
        <div className='LogoMain'>
          <img src={Logo} alt='NodeIoT'/>
        </div>
        {isMobile ? (
          <div className='MobileList'>
            <h2>
              <Trans i18nKey={t('Screen.Home.Step1.H2')}
                components={{
                  highLight: <span style={{ color: '#265FFF' }} />
                }} />
            </h2>
            <img src={DashboardDemo} width={"100%"} alt='Dashboard DEMO' />
            <h3>
              <Trans i18nKey={t('Screen.Home.Step1.H3')}
                components={{
                  highLight: <span style={{ color: '#265FFF' }} />
                }} />
            </h3>
          </div>
        ) : <img src={Cards} width={"100%"} alt='Cards' />}
      </div>
    </div>
  )
}





