import React from 'react';
import './BoxMain.css'
import { useSelector } from 'react-redux';

export default function BoxMain(props) {
    const isMobile = useSelector((state) => state.isMobile)
    return (
        <div className={`${isMobile? 'BoxMainMObile' : 'BoxMainBody'}`} style={{ flexDirection: props?.FlexDirection || '' }}>
            <div className='TextBox'>
                <h1> {props?.h1}</h1>
                {props.h2 && <h2>{props.h2}</h2>}
                {props.data.map((item, key) => (
                    <div className='ItemBox' key={"h2" + key}>
                        <h2 > {item?.h2}</h2>
                        <h3> {item?.h3}</h3>
                    </div>))
                }</div>
           {!isMobile && <div className='ImageBox'>
                {props?.Image}
            </div>}
        </div>
    )
}
