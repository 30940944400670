import React from 'react'
import './Contact.css';
import Header from '../../components/frames/Header';
import BoxMain from '../../components/frames/BoxMain';
import SendEmail from './SendEmail';
import plansThings from '../../images/plansThings.png'


export default function Contact() {
    return (
        <div className='ContactBody'>
            <Header
                Title={' Contato'}
            />
            <BoxMain
                h1=' Contato'
                data={[{
                    h2: <div style={{display: "flex", flexDirection:"row", justifyContent:"center", alignItems:"center", width: "100%",}}><img src={plansThings} width={"50%"} alt="Imagem contato" /> </div>,                   
                },]
            }
                Image={<SendEmail />}
            />
        </div>
    )
}
