import React from 'react';
import { useDispatch } from 'react-redux';
import './AccountPreview.css';
import Button from '../../../components/buttonNav/Buttons';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../images/LogotipoBeta.svg';
import { startFlowForm } from '../../../redux/actions/infoActions';

export default function AccountPreview(props) {
    const dispatch = useDispatch()
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    return (
        <div className={`${props.isMobile ? "AccountPreviewMobile" : "AccountPreviewBody"}`}>
                <div className='LogoAccount'>
                    <Logo style={{ cursor: 'pointer' }} width={'186px'}  onClick={() => navigate('/')} />
                </div>
                <div className='TitleAccount'>
                    <h2 style={{marginBottom: 0, fontSize:"26px" ,fontWeight: 600}}> 
                        {t('Account.PreTitle')}
                    </h2>
                    <h1>
                        <Trans i18nKey={t('Account.Title')}
                            components={{
                                highLight: <span style={{ color: '#265FFF' }} />,
                            }} />
                    </h1>
                </div>
                <div className='MessageAccount'>
                    <h2>
                        <Trans i18nKey={t('Account.Message')}
                            components={{
                                highLight: <span style={{ color: '#265FFF' }} />,
                                p: <p></p>
                            }} />
                    </h2>
                </div>
                <div className='ButtonFlow'>
                    <Button
                        Size={'Large'}
                        text={t('Account.AccountPreview.Button')}
                        onClicks={() => dispatch(startFlowForm({}))}
                    />
                </div>
        </div>
    )
}
