import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Alert.css'
import Button from '../../buttonNav/Buttons';
import Title from '../../title/Title';
import NiIcon from '../../niIcons/NiIcons';
import { addAlertNotification } from '../../../redux/actions/alertAction'
import { useTranslation } from "react-i18next";
import { addToastNotification } from '../../../redux/actions/toastAction';

export default function Alert(props) {
  const { t } = useTranslation('common');
  const info = useSelector((state) => state.alertNotification)
  const isMobile = useSelector((state) => state.isMobile)
  const dispatch = useDispatch()
  const STYLE = {
    backgroundColor: props.backgroundColor || 'rgba(0, 0, 0, 0.5)'
  }
  const cancelHandle = () => {
    dispatch(addToastNotification({icon:'edit', message: t('Notifications.Cancel'), status:'warning'}))
    dispatch(addAlertNotification({ status: false, info: {} }))
  }
  const submitHandle = () => {
    info.info.actionConfirm()
    dispatch(addAlertNotification({ status: false, info: {} }))
  }
  return (
  
    <div className='PopupFadeAlert' style={STYLE}>
      <div className={`PopupModalAlert ${isMobile && 'AlertMobile'}`}>
        <div className={`BackgroundAlert `}>
          <div className={`AlertBody`}>
            <div className='HeaderAlert'>
              <div className='IconAlert'>
                <NiIcon
                  name={info.info.icon || 'bell'}
                  style={{ width: isMobile? "50px" : '65px', height:  isMobile? "50px" :  '65px', fill: "#265FFF" }}
                  styleIcon={{ minX: "0", minY: "0", width: "25", height: "25" }}
                />

              </div>
            </div>
            <div className='MainAlert'>
              <Title
                title={t(`${info.info.message}`)}
                color={'#000000'}
                fontWeight={400}
                fontSize={isMobile ? 18 : 21}
              />
            </div>

            <div className='ButtonsAlert'>
              {!info.info?.hiddenCancel && 
              <Button
                Type={'Cancel'}
                text={info.info.cancelText || `${t('Notifications.Button.Cancel')}`}
                onClicks={cancelHandle}
              />}

              <Button
                text={info.info.confirmText || `${t('Notifications.Button.Ok')}`}
                onClicks={submitHandle }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  
}
